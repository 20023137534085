import { theme } from 'src/theme';
import styled from 'styled-components/macro';

export const ContentSection = styled.div({
  padding: '0 20px',
  margin: 'auto',
  [theme.mediaQueries.small]: {
    padding: '0 40px',
  },
});

export const H1 = styled.h1({
  margin: '20px 0 10px',
  textAlign: 'center',
  textTransform: 'uppercase',
});

export const H2 = styled.h2({
  margin: '20px 0 10px',
  textAlign: 'center',
  textTransform: 'uppercase',
});

export const H3 = styled.h3({
  margin: '5px',
  textAlign: 'center',
  textTransform: 'uppercase',
});

export const H4 = styled.h4({
  margin: 0,
  textTransform: 'uppercase',
});

export const TwoPanel = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  margin: '0 -20px',
  [theme.mediaQueries.small]: {
    flexDirection: 'row',
  },
});

export const Panel = styled.div({
  width: '90%',
  margin: '10px auto',
  display: 'flex',
  flexDirection: 'column',
  [theme.mediaQueries.small]: {
    width: '45%',
  },
});

export const UL = styled.ul({
  marginTop: 0,
  textAlign: 'left',
});
