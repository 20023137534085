import React from 'react';
import styled from 'styled-components/macro';
import { ContentSection, H3, H2, SmallBrawlerGroup } from '.';
import airAmazon from 'src/images/brawlers-dark/amazon_air.gif';
import doomWizard from 'src/images/brawlers-dark/wizard_doom.gif';
import waterKnight from 'src/images/brawlers-dark/knight_water.gif';
import doomBarbarian from 'src/images/brawlers-dark/barbarian_doom.gif';
import fireRogue from 'src/images/brawlers-dark/rogue_fire.gif';
import earthWitch from 'src/images/brawlers-dark/witch_earth.gif';
import logo from 'src/images/bb-logo.png';
import mainbg from 'src/images/bg/bg-a-toxic.png';
import firebg from 'src/images/bg/bg-a-fire.png';
import desertbg from 'src/images/bg/bg-a-desert.png';
import doombg from 'src/images/bg/bg-a-doom.png';
import { theme } from 'src/theme';
import earthAmazon from 'src/images/brawlers-dark/amazon_earth.gif';
import airBarbarian from 'src/images/brawlers-dark/barbarian_air.gif';
import fireWitch from 'src/images/brawlers-dark/witch_fire.gif';

import fireKnight from 'src/images/brawlers-dark/knight_fire.gif';
import doomRogue from 'src/images/brawlers-dark/rogue_doom.gif';
import airCleric from 'src/images/brawlers-dark/cleric_air.gif';

import waterMonk from 'src/images/brawlers-dark/monk_water.gif';
import waterWarrior from 'src/images/brawlers-dark/warrior_water.gif';
import earthWizard from 'src/images/brawlers-dark/wizard_earth.gif';
import gameLogoHorizontal from 'src/images/logos/game-horizontal-white.png';
import skaleLogoHorizontal from 'src/images/logos/skale-horizontal-white.svg';
import { Link } from 'react-router-dom';
import { Paths } from 'src/nav/paths';
import { SectionGap } from './SectionBreak';

export function KeyArt() {
  return (
    <StyledKeyArt>
      <StyledBlur>
        <KeyArtInner>
          <KeyArtHeroLeft1 src={waterKnight} />
          <KeyArtHeroLeft2 src={doomWizard} />
          <KeyArtHeroLeft3 src={airAmazon} />
          <KeyArtHeroRight1 src={earthWitch} />
          <KeyArtHeroRight2 src={doomBarbarian} />
          <KeyArtHeroRight3 src={fireRogue} />
          <KeyArtLogo src={logo} />
        </KeyArtInner>
      </StyledBlur>
    </StyledKeyArt>
  );
}

const StyledPanel = styled(Link)({
  color: theme.colors.white,
  backgroundColor: theme.colors.blue,
  padding: '20px 20px',
  margin: '0 0 10px',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  textDecoration: 'none',
  boxShadow: `0 0 3px 3px ${theme.colors.blueBlack}`,
  '> *': {
    margin: '0 !important',
  },
  ['&:hover']: {
    filter: 'brightness(65%)',
  },
  ['&:disabled']: {
    backgroundColor: theme.colors.mg,
    color: theme.colors.fg,
    cursor: 'unset',
    ['&:hover']: {
      filter: 'unset',
    },
  },
});

const CenteredContent = styled.div<{ bg: string }>((props) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  width: '100%',
  overflow: 'hidden',
  height: '360px',
  [theme.mediaQueries.smaller]: {},
  backgroundImage: `linear-gradient(
      rgba(0, 0, 0, 0.3), 
      rgba(0, 0, 0, 0.3)
    ), url(${props.bg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backdropFilter: 'blur(2px) grayscale(90%)',
}));

const HomeTriple = styled.div({
  width: '100%',
  margin: '60px auto 0',
});
const StyledRow = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '500px',
  margin: '10px auto',
  [theme.mediaQueries.small]: {
    flexDirection: 'row',
  },
});

export const StyledMainImg = styled.img({
  maxHeight: '40px',
  margin: '15px auto 15px',
});

export const SectionCta = styled.h3({
  margin: '20px',
  maxWidth: '200px',
  textShadow: '2px 2px 3px black',
  [theme.mediaQueries.smaller]: {
    margin: '60px 200px 30px',
  },
});

export function HomeSection() {
  return (
    <>
      <KeyArt />
      <SectionGap />
      <ContentSection>
        <H3>PLAY-TO-EARN RPG POWERED BY:</H3>
        <StyledRow>
          <StyledMainImg src={skaleLogoHorizontal} />
          <StyledMainImg src={gameLogoHorizontal} />
        </StyledRow>
      </ContentSection>
      <HomeTriple>
        <CenteredContent bg={firebg}>
          <StyledPanel to={Paths.buy}>
            <H2>COLLECT</H2>
          </StyledPanel>
          <SectionCta>Collect Block Brawler NFTs with unique stats and abilities</SectionCta>
          <SmallBrawlerGroup imageUrls={[earthAmazon, airBarbarian, fireWitch]} isRight={true} />
        </CenteredContent>
        <CenteredContent bg={doombg}>
          <StyledPanel to={Paths.play}>
            <H2>BATTLE</H2>
          </StyledPanel>
          <SectionCta>Make a team of three Brawlers and take on others in the Arena</SectionCta>
          <SmallBrawlerGroup imageUrls={[fireKnight, doomRogue, airCleric]} isRight={true} />
        </CenteredContent>
        <CenteredContent bg={desertbg}>
          <StyledPanel to={Paths.about}>
            <H2>EARN</H2>
          </StyledPanel>
          <SectionCta>Win fights in the Arena to earn BRAWL tokens, XP, and Gold</SectionCta>
          <SmallBrawlerGroup imageUrls={[waterMonk, waterWarrior, earthWizard]} isRight={true} />
        </CenteredContent>
      </HomeTriple>
    </>
  );
}

const StyledKeyArt = styled.div({
  margin: 0,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundImage: `linear-gradient(
      rgba(0, 0, 0, 0.3), 
      rgba(0, 0, 0, 0.3)
    ), url(${mainbg})`,
  height: '300px',
  width: '100%',
  maxWidth: '800px',
  overflow: 'hidden',
  [theme.mediaQueries.small]: {
    height: '400px',
  },
});

const StyledBlur = styled.div({
  height: '100%',
  width: '100%',
});

const KeyArtInner = styled.div({
  top: '20px',
  position: 'relative',
  display: 'flex',
  width: '50%',
  maxWidth: '350px',
  height: '94%',
  margin: 'auto',
});

const KeyArtLogo = styled.img({
  width: '200px',
  height: '151px',
  margin: '80px auto',
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  [theme.mediaQueries.small]: {
    width: '260px',
    height: '197px',
    margin: '100px auto',
  },
});

const KeyArtHero = styled.img({
  position: 'absolute',
  width: '150px',
  margin: 'auto',
  [theme.mediaQueries.small]: {
    width: '200px',
  },
});

const KeyArtHeroRight = styled(KeyArtHero)({
  transform: 'scaleX(-1)',
});

const KeyArtHeroRight1 = styled(KeyArtHeroRight)({
  left: '95%',
  bottom: '40%',
});

const KeyArtHeroRight2 = styled(KeyArtHeroRight)({
  left: '75%',
  bottom: '20%',
});

const KeyArtHeroRight3 = styled(KeyArtHeroRight)({
  left: '55%',
  bottom: '0%',
});

const KeyArtHeroLeft1 = styled(KeyArtHero)({
  right: '95%',
  bottom: '40%',
});

const KeyArtHeroLeft2 = styled(KeyArtHero)({
  right: '75%',
  bottom: '20%',
});

const KeyArtHeroLeft3 = styled(KeyArtHero)({
  right: '55%',
  bottom: '0%',
});
