import earthAmazon from 'src/images/brawlers-dark/amazon_earth.gif';
import airBarbarian from 'src/images/brawlers-dark/barbarian_air.gif';
import fireWitch from 'src/images/brawlers-dark/witch_fire.gif';

import waterKnight from 'src/images/brawlers-dark/knight_water.gif';
import doomRogue from 'src/images/brawlers-dark/rogue_doom.gif';
import airCleric from 'src/images/brawlers-dark/cleric_air.gif';

import waterMonk from 'src/images/brawlers-dark/monk_water.gif';
import fireWarrior from 'src/images/brawlers-dark/warrior_fire.gif';
import earthWizard from 'src/images/brawlers-dark/wizard_earth.gif';

import fireRogue from 'src/images/brawlers-dark/rogue_fire.gif';
import earthBarbarian from 'src/images/brawlers-dark/barbarian_earth.gif';
import doomWizard from 'src/images/brawlers-dark/wizard_doom.gif';

import fireKnight from 'src/images/brawlers-dark/knight_fire.gif';
import waterCleric from 'src/images/brawlers-dark/cleric_water.gif';
import airMonk from 'src/images/brawlers-dark/monk_air.gif';

import airWitch from 'src/images/brawlers-dark/witch_air.gif';
import waterWarrior from 'src/images/brawlers-dark/warrior_water.gif';
import doomAmazon from 'src/images/brawlers-dark/amazon_doom.gif';

export enum Currency {
  GAME = 'GAME',
  SKALE = 'SKL',
  ETH = 'ETH',
}

export interface CSPData {
  packId: number;
  images: [string, string, string];
  title: string;
  price: number;
  max: number;
  brawlTokens: number;
  brawlerLevel: number;
  legendaryCount: number;
  currency: Currency;
  available: number;
  isOnSale: boolean;
}

export function getCSPPacks(): [CSPData, CSPData, CSPData, CSPData, CSPData, CSPData] {
  const isOnSale = false;
  const cspPacks: [CSPData, CSPData, CSPData, CSPData, CSPData, CSPData] = [
    {
      packId: 1,
      images: [airWitch, waterWarrior, doomAmazon],
      title: 'Legendary Pack',
      brawlerLevel: 15,
      brawlTokens: 20000,
      legendaryCount: 3,
      price: 1,
      max: 300,
      currency: Currency.ETH,
      available: 30,
      isOnSale: isOnSale,
    },
    {
      packId: 2,
      images: [fireKnight, waterCleric, airMonk],
      title: 'Starter Pack',
      brawlerLevel: 2,
      brawlTokens: 1500,
      legendaryCount: 0,
      price: 0.08,
      max: 5000,
      currency: Currency.ETH,
      available: 500,
      isOnSale: isOnSale,
    },
    {
      packId: 3,
      images: [waterMonk, fireWarrior, earthWizard],
      title: 'SKALE Value Pack',
      brawlerLevel: 1,
      brawlTokens: 500,
      legendaryCount: 0,
      price: 500,
      max: 5000,
      currency: Currency.SKALE,
      available: 500,
      isOnSale: isOnSale,
    },
    {
      packId: 4,
      images: [fireRogue, earthBarbarian, doomWizard],
      title: 'SKALE Bonus Pack',
      brawlerLevel: 10,
      brawlTokens: 4500,
      legendaryCount: 3,
      price: 5000,
      max: 1000,
      currency: Currency.SKALE,
      available: 100,
      isOnSale: isOnSale,
    },
    {
      packId: 5,
      images: [waterKnight, doomRogue, airCleric],
      title: 'Play To Earn Pack',
      brawlerLevel: 5,
      brawlTokens: 2500,
      legendaryCount: 0,
      price: 0.15,
      max: 2000,
      currency: Currency.ETH,
      available: 200,
      isOnSale: isOnSale,
    },
    {
      packId: 6,
      images: [earthAmazon, airBarbarian, fireWitch],
      title: 'Brawler Pack',
      brawlerLevel: 5,
      brawlTokens: 1000,
      legendaryCount: 3,
      price: 3000,
      max: 1500,
      currency: Currency.GAME,
      available: 150,
      isOnSale: isOnSale,
    },
  ];
  return cspPacks;
}
