import React from 'react';
// import { useCalls, useEtherBalance, useEthers } from '@usedapp/core';
import { CommunitySaleSection, ContentSection, H1, HomeSection } from 'src/components';
// import { callList, destructureResults } from 'src/calls';

// import { BigNumber, ethers } from 'ethers';
import { PageFrame } from '.';
import { BuyIntro } from './Buy';
import { TeamGrid } from 'src/components/TeamGrid';
import { Roadmap } from 'src/components/Roadmap';
import { SectionBreak } from 'src/components/SectionBreak';

export function HomePage() {
  return (
    <PageFrame>
      <HomeSection />
      <SectionBreak />
      <ContentSection>
        <BuyIntro />
      </ContentSection>
      <CommunitySaleSection />
      <SectionBreak />
      <TeamGrid />
      <SectionBreak />
      <Roadmap />
    </PageFrame>
  );
}
