import React from 'react';
import { theme } from 'src/theme';
import styled from 'styled-components/macro';

export const SectionBreak = styled.div({
  width: '80%',
  maxWidth: '480px',
  height: '3px',
  margin: '40px auto',
  backgroundColor: theme.colors.blueLight,
  [theme.mediaQueries.small]: {
    margin: '6`0px auto',
  },
});

export const SectionGap = styled.div({
  width: '80%',
  maxWidth: '480px',
  height: '3px',
  margin: '30px auto',
});
