import React from 'react';
import { theme } from 'src/theme';
import styled from 'styled-components/macro';
import { ContentSection, H2, H3, UL } from './ContentSection';

const StyledRoadmap = styled.div({
  maxWidth: '480px',
  margin: '40px auto',
});

const Completed = styled.li({
  textAlign: 'left',
  listStyle: 'none',
  margin: '0 0 0 -18px',
  ':before': {
    color: theme.colors.orange,
    content: "'✓ '",
  },
});

export function Roadmap() {
  return (
    <ContentSection>
      <H2>Roadmap</H2>
      <p>
        We have no fixed roadmap for Block Brawlers, for two reasons. Firstly, we’re launching with a fully working
        game, not waiting 12 months for a game to be created. And secondly, we’re looking to build the features and
        projects that our community wants the most, rather than dictating what should be built.
      </p>
      <p>But… here are some things we really want to do:</p>
      <StyledRoadmap>
        <H3>Phase 1: Play to Earn</H3>
        <UL>
          <Completed>
            <b>May 2022</b>: Block Brawlers NFTs launched
          </Completed>
          <Completed>
            <b>May 2022</b>: ERC-20 BRAWL token launched
          </Completed>
          <Completed>
            <b>May 2022</b>: Block Brawlers play to earn launch
          </Completed>
        </UL>
        <H3>Phase 2: PvE</H3>
        <UL>
          <Completed>
            <b>2022</b>: Leaderboards with prizes
          </Completed>
          <li>
            <b>2022</b>: New exchange listings
          </li>
          <li>
            <b>2022</b>: Block Brawlers dungeons and PvE
          </li>
        </UL>
        <H3>Phase 3: Mobile Game</H3>
        <UL>
          <li>
            <b>2023</b>: Block Brawlers in-game NFTs
          </li>
          <li>
            <b>2023</b>: Block Brawlers Quests
          </li>
          <li>
            <b>2023</b>: Block Brawlers releases on mobile
          </li>
        </UL>
      </StyledRoadmap>
    </ContentSection>
  );
}
