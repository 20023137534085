import styled from 'styled-components/macro';

export * from './WalletButton';
export * from './HomeSection';
export * from './Footer';
export * from './Header';
export * from './Buttons';
export * from './ContentSection';
export * from './BrawlerGroup';
export * from './ContactSection';
export * from './CommunitySaleSection';

export const StyledImg = styled.img({
  maxWidth: '160px',
  maxHeight: '24px',
  margin: '10px auto 10px',
});
