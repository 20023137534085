import React from 'react';
import { ContentSection, Panel, H2, TwoPanel, StyledImg, OutLink } from 'src/components';
import ethLogoHorizontal from 'src/images/logos/eth-horizontal-white.svg';
import gameLogoHorizontal from 'src/images/logos/game-horizontal-white.png';
import skaleLogoHorizontal from 'src/images/logos/skale-horizontal-white.svg';
import { SocialLinks } from 'src/calls';
import { SectionBreak } from './SectionBreak';

export function JoinCommunitySection() {
  return (
    <>
      <ContentSection>
        <H2>Get Ready!</H2>
        <p>
          Block Brawlers will be playable starting on May 25, 2022. In the mean time, get involved with the community,
          and get ready for the Community Sale:
        </p>
      </ContentSection>
      <SectionBreak />
      <ContentSection>
        <TwoPanel>
          <Panel>
            <H2>Join the Community</H2>
            <p>
              Connect with the Block Brawlers community to get the inside scoop on the game, upcoming releases, and
              special events.
            </p>
            <p>
              Join the <OutLink href={SocialLinks.DISCORD}>Block Brawlers Discord</OutLink>
            </p>
            <p>
              Join the <OutLink href={SocialLinks.TELEGRAM}>Block Brawlers Telegram</OutLink>
            </p>
            <p>
              Follow <OutLink href={SocialLinks.TWITTER}>GAME Credits on Twitter</OutLink>
            </p>
            <p>Sign up for the Block Brawlers mailing list by filling out the form below.</p>
          </Panel>
          <Panel>
            <H2>Get Ready for the Sale</H2>
            <p>You can buy Block Brawlers with Ethereum, Skale, and GAME Credits</p>
            <StyledImg src={ethLogoHorizontal} />
            <p>You can buy Brawlers and Brawl using Ethereum; you&apos;ll also need Ethereum for transaction fees.</p>
            <StyledImg src={skaleLogoHorizontal} />
            <p>
              Make sure you have enough Skale in your account to buy your Brawlers with Skale. You can buy Skale on
              Coinbase, Binance, and other exchanges.
            </p>
            <StyledImg src={gameLogoHorizontal} />
            <p>
              Make sure you have enough GAME Credits in your account to buy your Brawlers with GAME Credits. You can buy
              GAME Credits on Bittrex, Zonda, and Uniswap.
            </p>
          </Panel>
        </TwoPanel>
      </ContentSection>
    </>
  );
}
