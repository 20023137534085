import React, { useState } from 'react';
import { WalletButton } from 'src/components';
import { theme } from 'src/theme';
import styled from 'styled-components/macro';
import { RightNav } from './RightNav';

const StyledBurgerHolder = styled.div({
  width: '40px',
  [theme.mediaQueries.small]: {
    width: 0,
  },
});

const StyledBurger = styled.div<{ open: boolean }>`
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 15px;
  right: 20px;
  z-index: 20;
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${theme.colors.blueLight};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? 'translateX(100%)' : 'translateX(0)')};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

const NavRight = styled.div({
  display: 'flex',
  [theme.mediaQueries.small]: {
    flexDirection: 'row-reverse',
  },
});

export const Burger = () => {
  const [open, setOpen] = useState(false);

  return (
    <NavRight>
      <WalletButton />
      <StyledBurgerHolder>
        <StyledBurger open={open} onClick={() => setOpen(!open)}>
          <div />
          <div />
          <div />
        </StyledBurger>
      </StyledBurgerHolder>
      <RightNav open={open} onSelect={() => setOpen(false)} />
    </NavRight>
  );
};
