import React from 'react';
import styled from 'styled-components/macro';
import { Panel, UL, BuyButton, StyledImg } from '.';
// import ethLogo from 'src/images/logos/eth.svg';
// import gameLogo from 'src/images/logos/game.png';
// import skaleLogo from 'src/images/logos/skale.png';
import ethLogoHorizontal from 'src/images/logos/eth-horizontal-white.svg';
import gameLogoHorizontal from 'src/images/logos/game-horizontal-white.png';
import skaleLogoHorizontal from 'src/images/logos/skale-horizontal-white.svg';

import { BrawlerGroup } from './BrawlerGroup';
import { StyledHeader } from 'src/components';
import { theme } from 'src/theme';
import { UserSaleData, CSPData, Currency } from 'src/calls';
import { abis, addresses, targetChainId } from 'src/abis';
import { Contract } from '@ethersproject/contracts';
import { useContractFunction, useEthers } from '@usedapp/core';
import { ethers } from 'ethers';
import { WalletButton } from './WalletButton';
import { InfoButton } from './Buttons';

const StyledCommunitySalePanel = styled(Panel)({
  border: `solid 3px ${theme.colors.mg}`,
  padding: '20px',
  margin: '20px auto',
  width: '280px',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '20px',
  ['button']: {
    margin: 0,
  },
});

const StyledP = styled.p({
  margin: 0,
  fontWeight: 700,
  textTransform: 'uppercase',
});

const StyledUL = styled(UL)({
  textAlign: 'left',
  margin: '0 0 20px -20px',
});

function useBuyPack(): { state: unknown; send: (packId: number, options: ethers.PayableOverrides) => unknown } {
  const contract = addresses.brawlerBuyer && new Contract(addresses.brawlerBuyer, abis.brawlerBuyer);
  const { state, send } = useContractFunction(contract, 'buyBrawlers', {});
  return { state, send };
}
function useAllowance(currency: string): (sender: string, value: string, options: ethers.PayableOverrides) => unknown {
  const contract = currency ? new Contract(currency, abis.erc20) : null;
  const { send } = useContractFunction(contract, 'approve', {});
  return send;
}

export function CommunitySalePanel(props: { cspData: CSPData; userData: UserSaleData; index: number }) {
  const { chainId } = useEthers();
  const { packId, images, title, brawlerLevel, legendaryCount, brawlTokens } = props.cspData;
  const { account, ethBalance, gameBalance, sklBalance, sales, isOnSale } = props.userData;
  const { currencyAddress, price, available, allowance, hasBought, sold } = sales[props.index];
  const wrongChain = chainId != targetChainId;
  const nonLegendaryCount = 3 - legendaryCount;
  const value = (((brawlerLevel * (brawlerLevel + 1)) / 2) * 100 + 900) * 3 + brawlTokens;
  const usdValue = Math.floor(value * 0.0015) * 100;
  const unsold = available - sold;
  const isAllowed = allowance != '0';
  const currency =
    currencyAddress === addresses.game
      ? Currency.GAME
      : currencyAddress === addresses.skl
      ? Currency.SKALE
      : Currency.ETH;
  const balance =
    currencyAddress === addresses.game ? gameBalance : currencyAddress === addresses.skl ? sklBalance : ethBalance;
  const insufficientFunds = Number(balance) < Number(price);

  const { send: sendBuyBrawlers } = useBuyPack();
  const sendAllowance = useAllowance(currencyAddress);
  const setAllowance = () =>
    sendAllowance(addresses.brawlerBuyer, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff', {});
  const buyBrawlers = (packId: number) => {
    const options = {
      value: currency == Currency.ETH ? ethers.utils.parseUnits(price, 'ether') : '0',
    };
    sendBuyBrawlers(packId, options);
  };

  return (
    <StyledCommunitySalePanel>
      <BrawlerGroup imageUrls={images} />
      <StyledHeader>
        #{props.index}: {title}
      </StyledHeader>
      <StyledImg
        src={
          currency == Currency.GAME
            ? gameLogoHorizontal
            : currency == Currency.SKALE
            ? skaleLogoHorizontal
            : ethLogoHorizontal
        }
      />
      <StyledP>
        {!isOnSale
          ? `${available.toLocaleString()} packs`
          : `${unsold.toLocaleString()} of ${available.toLocaleString()} remaining`}
      </StyledP>
      <StyledUL>
        {legendaryCount > 0 && (
          <li>
            {legendaryCount} LEGENDARY Level {brawlerLevel} Brawler{legendaryCount > 1 ? 's' : ''}
          </li>
        )}
        {nonLegendaryCount > 0 && (
          <li>
            {nonLegendaryCount} Level {brawlerLevel} Brawler{nonLegendaryCount > 1 ? 's' : ''}
          </li>
        )}
        <li>{brawlTokens.toLocaleString()} BRAWL tokens</li>
        <li>
          Est Value: {value.toLocaleString()} BRAWL (~${usdValue.toLocaleString()})
        </li>
        <li>
          Price: {price} {currency.toString()}
        </li>
      </StyledUL>
      {!account || wrongChain ? (
        <WalletButton />
      ) : isOnSale ? (
        hasBought ? (
          <InfoButton onClick={() => window.open('https://play.blockbrawlers.com', '_blank')}>PLAY NOW</InfoButton>
        ) : isAllowed || insufficientFunds ? (
          <BuyButton disabled={unsold <= 0 || insufficientFunds} onClick={() => buyBrawlers(packId)}>
            {insufficientFunds ? `NOT ENOUGH ${currency.toString()}` : `BUY: ${price} ${currency.toString()}`}
          </BuyButton>
        ) : (
          <InfoButton disabled={unsold <= 0} onClick={() => setAllowance()}>
            Approve {currency.toString()}
          </InfoButton>
        )
      ) : (
        <BuyButton disabled={true}>Coming Soon</BuyButton>
      )}
    </StyledCommunitySalePanel>
  );
}
