import React from 'react';
import styled from 'styled-components/macro';
import { TwoPanel, H2, OutLink } from '.';

import { ContentSection } from './ContentSection';
import { useCalls, useEtherBalance, useEthers } from '@usedapp/core';
import { callList, destructureResults } from 'src/calls';
import { BigNumber, ethers } from 'ethers';
import { CommunitySalePanel } from './SalePanel';
import { getCSPPacks } from 'src/calls/cspData';
import { targetChainId } from 'src/abis';

export const StyledHeader = styled.h3({
  margin: '20px 0 0',
  textAlign: 'center',
  textTransform: 'uppercase',
});

export function CommunitySaleSection() {
  const { account } = useEthers();
  const ethResult = useEtherBalance(account, { chainId: targetChainId }) ?? BigNumber.from(0);
  const ethBalance = ethers.utils.formatEther(ethResult);
  // Read more about useDapp on https://usedapp.io/

  //const { loading, error: subgraphQueryError, data } = useQuery(GET_TRANSFERS);
  const callResults = useCalls(callList(account), { chainId: targetChainId }) ?? [];
  const userSaleData = destructureResults(account, ethBalance, callResults);

  const p2ePacks = getCSPPacks();
  const totalBrawlers = p2ePacks.reduce((prev, curr) => prev + curr.max * 3, 0);
  const totalLegendary = p2ePacks.reduce((prev, curr) => prev + curr.max * curr.legendaryCount, 0);
  const totalBrawl = p2ePacks.reduce((prev, curr) => prev + curr.max * curr.brawlTokens, 0);

  // If allowance of GAME/SKL isn't set, show the set allowance button.

  return (
    <ContentSection>
      <H2>Community Sale</H2>
      <p>
        There are six Brawler Packs available in the player community sale, two each priced in ETH, SKL, and GAME. The
        total BRAWL available in this sale is {totalBrawl.toLocaleString()}, and there are{' '}
        {(totalBrawlers - totalLegendary).toLocaleString()} regular Brawlers and {totalLegendary.toLocaleString()}{' '}
        Legendary Brawlers available. Regular Brawlers can be bought in the store, but there can never be more than
        10,000 Legendary Brawlers.
      </p>
      <p>Brawlers are released in waves. The first wave is 10% of the total supply.</p>
      <p>You can buy one copy of each of the six Brawler Packs, but you can&apos;t buy any one pack more than once.</p>
      <TwoPanel>
        <CommunitySalePanel cspData={p2ePacks[0]} userData={userSaleData} index={1} />
        <CommunitySalePanel cspData={p2ePacks[1]} userData={userSaleData} index={2} />
      </TwoPanel>
      <TwoPanel>
        <CommunitySalePanel cspData={p2ePacks[2]} userData={userSaleData} index={3} />
        <CommunitySalePanel cspData={p2ePacks[3]} userData={userSaleData} index={4} />
      </TwoPanel>
      <TwoPanel>
        <CommunitySalePanel cspData={p2ePacks[4]} userData={userSaleData} index={5} />
        <CommunitySalePanel cspData={p2ePacks[5]} userData={userSaleData} index={6} />
      </TwoPanel>
      <H2>Brawl Chain Bridge</H2>
      <p>
        When you buy your Brawlers and BRAWL tokens here, they&apos;re automatically transferred to the Brawl Chain, so
        you can play Block Brawlers immediately. Transfers take 5 to 10 minutes to complete. Then, go to{' '}
        <OutLink href={'https://play.blockbrawlers.com'}>play.blockbrawlers.com</OutLink> to view your Brawlers and
        enter the Arena.
      </p>
    </ContentSection>
  );
}
