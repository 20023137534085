// lib/hubspot.js
import axios from './axios';

export const submitHubspotForm = async (email: string) => {
  console.log('email', email);
  const portalId = '20883044'; // hubspot portal ID
  const formGuid = '6e1fbe40-f525-46d9-ae44-f1206dad4997'; // hubspot form GUID
  const config = {
    // important!
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const response = await axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      {
        portalId,
        formGuid,
        fields: [
          {
            name: 'email',
            value: email,
          },
        ],
      },
      config,
    );
    return response;
  } catch (error) {
    return null;
  }
};
