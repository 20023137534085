import React from 'react';

import { Header, Footer } from './components';
import { Navbar } from './nav/NavBar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AboutPage, BuyPage, HomePage, ToSPage } from './pages';
import styled from 'styled-components/macro';
import { Paths } from './nav/paths';
import { PlayPage } from './pages/Play';
import mainbg from 'src/images/bg/bg-a-doom.png';
import { theme } from 'src/theme';

export const Body = styled.div({
  width: '100%',
  alignItems: 'center',
  color: theme.colors.fg,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: 0,
});

const StyledBG = styled.div({
  position: 'fixed',
  width: '106%',
  height: '103vh',
  left: '-3%',
  backgroundColor: theme.colors.black,
  backgroundImage: `linear-gradient(
      rgba(0, 25, 50, 0.6), 
      rgba(0, 25, 50, 0.6)
    ), url(${mainbg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundAttachment: 'fixed',

  filter: 'blur(2px)',
  zIndex: -1,
});

export function App() {
  return (
    <BrowserRouter>
      <StyledBG />
      <Body>
        <Routes>
          <Route path={Paths.home} element={<HomePage />} />
          <Route path={Paths.buy} element={<BuyPage />} />
          <Route path={Paths.about} element={<AboutPage />} />
          <Route path={Paths.terms} element={<ToSPage />} />
          <Route path={Paths.play} element={<PlayPage />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
      </Body>
      <Header>
        <Navbar />
      </Header>
      <Footer />
    </BrowserRouter>
  );
}
