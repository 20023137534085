import styled from 'styled-components/macro';
import { theme } from 'src/theme';

export const Button = styled.button({
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
  fontFamily: 'Eczar, Georgia, "Times New Roman", Times, serif',
  fontSize: '16px',
  lineHeight: '16px',
  fontWeight: 700,
  height: '42px',
  margin: '10px 20px',
  padding: '12px 24px',
  textAlign: 'center',
  textDecoration: 'none',
  textTransform: 'uppercase',
  ['&:hover']: {
    filter: 'brightness(65%)',
  },
  ['&:disabled']: {
    backgroundColor: theme.colors.grey,
    color: theme.colors.greyBlack,
    cursor: 'unset',
    ['&:hover']: {
      filter: 'unset',
    },
  },
});

export const StealthButton = styled(Button)({
  backgroundColor: '#00000000',
  color: theme.colors.blueLight,
  minWidth: '180px',
  pointerEvents: 'none',
  textTransform: 'unset',
});

export const InfoButton = styled(Button)({
  backgroundColor: theme.colors.blueLight,
  color: theme.colors.greyBlack,
  minWidth: '180px',
  ['&:disabled']: {
    backgroundColor: '#00000000',
    color: 'unset',
  },
});

export const PrimaryButton = styled(Button)({
  backgroundColor: theme.colors.orange,
  color: theme.colors.blueBlack,
  minWidth: '180px',
});

export const BuyButton = styled(Button)({
  backgroundColor: theme.colors.orange,
  color: theme.colors.greyBlack,
  margin: 0,
  minWidth: '180px',
});

export const OutLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})({
  color: theme.colors.blueLight,
  textDecoration: 'none',
  ['&:hover']: {
    textDecoration: 'none',
  },
  [theme.mediaQueries.small]: {
    ['&:hover']: {
      textDecoration: 'underline',
    },
  },
});

export const OutLinkButton = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  border: none;
  color: #61dafb;
  cursor: pointer;
  font-size: 16px;
  margin: 10px 20px;
  padding: 12px 24px;
  height: 18px;
  text-align: center;
  text-decoration: none;
`;
