import React from 'react';
import { SocialLinks } from 'src/calls';
import { H1, CommunitySaleSection, ContentSection, OutLink } from 'src/components';
import { SectionBreak, SectionGap } from 'src/components/SectionBreak';
import { PageFrame } from '.';

export function BuyPage() {
  return (
    <PageFrame>
      <ContentSection>
        <SectionGap />
        <BuyIntro />
        <SectionBreak />
        <CommunitySaleSection />
      </ContentSection>
      <SectionBreak />
    </PageFrame>
  );
}

export function BuyIntro() {
  return (
    <>
      <H1>Block Brawlers Community Sale</H1>
      <p>
        The community sale is now live. Sign up for our mailing list, and connect with us on{' '}
        <OutLink href={SocialLinks.DISCORD}>Discord</OutLink>, <OutLink href={SocialLinks.TWITTER}>Twitter</OutLink>,
        and <OutLink href={SocialLinks.TELEGRAM}>Telegram</OutLink> for the latest updates.
      </p>
    </>
  );
}
