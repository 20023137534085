import { shortenAddress, useEthers, useLookupAddress } from '@usedapp/core';
import React, { useEffect, useState } from 'react';
import { targetChainId } from 'src/abis';
import { switchToMainnet } from 'src/calls';

import { StealthButton, PrimaryButton } from '.';

export function WalletButton() {
  const [rendered, setRendered] = useState('');

  const ens = useLookupAddress();
  const { account, activateBrowserWallet, deactivate, chainId, library } = useEthers();

  const wrongChain = chainId != targetChainId;

  useEffect(() => {
    if (ens) {
      setRendered(ens);
    } else if (account) {
      setRendered(shortenAddress(account));
    } else {
      setRendered('');
    }
  }, [account, ens, setRendered]);

  return !account ? (
    <PrimaryButton
      onClick={() => {
        if (!account) {
          activateBrowserWallet();
        } else {
          deactivate();
        }
      }}
    >
      Connect
    </PrimaryButton>
  ) : wrongChain ? (
    <PrimaryButton onClick={() => switchToMainnet(library.getSigner(account))}>Switch to Mainnet</PrimaryButton>
  ) : (
    <StealthButton>{rendered}</StealthButton>
  );
}
