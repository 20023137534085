// JS reimplementation of Style Closet scales for use in styled-components
const colors = {
  white: '#ffffff',
  bg: '#8c5200',
  mg: '#9fbcbf',
  fg: '#ffffff',
  primary: '#fa0',
  info: '#9fbcbf',
  black: '#020303',
  blueLight: '#7fd2ff',
  blue: '#00a6ff',
  blueDark: '#0087cf',
  blueBlack: '#004367',
  blueBlack60: '#00436788',
  orangeLight: '#ffd47f',
  orange: '#ffaa00',
  orangeDark: '#ff7b00',
  orangeBlack: '#7f3d00',
  greyLight: '#cfdddf',
  grey: '#9fbcbf',
  greyDark: '#7b999c',
  greyBlack: '#1d2627',
  brownLight: '#f3c37f',
  brown: '#d68513',
  brownDark: '#8c5200',
  brownBlack: '#462800',
};

// These breakpoints aren't correct - we need to work on our global breakpoints, and put them here
const breakpoints = ['768px', '600px', '46.875em'];
const mediaQueries = {
  small: `@media screen and (min-width: ${breakpoints[0]})`,
  smaller: `@media screen and (min-width: ${breakpoints[1]})`,
  large: `@media screen and (min-width: ${breakpoints[2]})`,
};
const fontSizes = ['.8rem', '1.0rem', '1.2rem', '1.4rem', '1.6rem', '1.8rem', '2.0rem', '2.4rem'];
const space = ['0', '.4rem', '.8rem', '1.2rem', '1.6rem', '2.0rem', '3.2rem', '4.8rem', '6.4rem', '9.6rem'];

const spacing = {
  mobileHalf: '8px',
  mobileSingle: '16px',
  mobileDouble: '32px',
  desktopSingle: '24px',
  desktopDouble: '48px',
  maxContentWidth: '800px',
};

export interface StyleClosetTheme {
  breakpoints: string[];
  fontSizes: string[];
  space: string[];
  colors: { [key in keyof typeof colors]: string };
  mediaQueries: { [key in keyof typeof mediaQueries]: string };
  fontWeights: {
    light: number;
    regular: number;
    bold: number;
  };
  spacing: {
    mobileHalf: string;
    mobileSingle: string;
    mobileDouble: string;
    desktopSingle: string;
    desktopDouble: string;
    maxContentWidth: string;
  };
}

export const theme: StyleClosetTheme = {
  breakpoints,
  fontSizes,
  space,
  colors,
  mediaQueries,
  fontWeights: {
    light: 300,
    regular: 500,
    bold: 700,
  },
  spacing,
};
