import axios from 'axios';

import axiosRetry from 'axios-retry';

const acceptedStatusCode = [408, 409, 502, 503, 504, 429];

//TODO: create logic to handle 429 status code

// Exponential back-off retry delay between requests
axiosRetry(axios, {
  retries: 7,
  retryCondition: (error) => {
    if (acceptedStatusCode.includes(error.response.status)) return true;
    return false;
  },
  //TODO: increase the initial rate of the exponential backoff
  retryDelay: axiosRetry.exponentialDelay,
});

let pendingRequest = [];

axios.interceptors.request.use(
  function (config) {
    if (pendingRequest.find((c) => c == config.url)) {
      const CancelToken = axios.CancelToken;
      return {
        ...config,
        cancelToken: new CancelToken((cancel) => cancel('Cancel repeated request')),
      };
    }
    pendingRequest.push(config.url);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  function (response) {
    pendingRequest = pendingRequest.filter((value) => value !== response.config.url);
    return response;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default axios;
