import React from 'react';
import {
  ContentSection,
  Panel,
  H1,
  H2,
  H3,
  H4,
  UL,
  KeyArt,
  BrawlerGroup,
  CommunitySaleSection,
  OutLink,
} from 'src/components';
import { PageFrame } from '.';
import earthAmazon from 'src/images/brawlers-dark/amazon_earth.gif';
import airBarbarian from 'src/images/brawlers-dark/barbarian_air.gif';
import fireWitch from 'src/images/brawlers-dark/witch_fire.gif';

import waterKnight from 'src/images/brawlers-dark/knight_water.gif';
import doomRogue from 'src/images/brawlers-dark/rogue_doom.gif';
import airCleric from 'src/images/brawlers-dark/cleric_air.gif';

import waterMonk from 'src/images/brawlers-dark/monk_water.gif';
import fireWarrior from 'src/images/brawlers-dark/warrior_fire.gif';
import earthWizard from 'src/images/brawlers-dark/wizard_earth.gif';
import { TeamGrid } from 'src/components/TeamGrid';
import { Roadmap } from 'src/components/Roadmap';
import { SectionBreak, SectionGap } from 'src/components/SectionBreak';

export function AboutPage() {
  return (
    <PageFrame>
      <SectionGap />
      <ContentSection>
        <H1>Block Brawlers</H1>
        <H3>A play-to-earn RPG powered by SKALE and GAME Credits</H3>
        <p>
          For thousands of years the evil King Fiatus ruled over the five great clans of Fungibalia. He and his allies
          enjoyed the fruits of their wealth and power, while all the rest suffered. “Enough is enough!” cried the
          people, and they rose up against Fiatus. But the king’s armies, well equipped and well trained, squashed the
          rebellion.{' '}
        </p>
        <p>
          When all seemed lost, the elders met in secret, pooled their resources and used their most powerful magic to
          forge an artifact that could empower the people - they called it The Block. Anyone who touched it gained
          abilities beyond that of mortal man, but the power was raw and wild. The clans would need to learn to wield
          its gifts.
        </p>
        <Panel>
          <BrawlerGroup imageUrls={[earthAmazon, airBarbarian, fireWitch]} />
        </Panel>
        <p>
          The Elders brought warriors together in secret competitions. Here they could test their newfound powers
          against other block-enhanced brawlers, training themselves to become unstoppable warriors. The clans came to
          know these combatants as the Block Brawlers.
        </p>
        <p>
          Those who rise to the top will lead the charge against King Fiatus and his allies. It is time once again to
          battle, so train your warriors to become the best of all Block Brawlers!
        </p>
        <p>Get ready to brawl!</p>
        <SectionBreak />
        <H2>DESIGN OVERVIEW</H2>
        <p>
          <b>Overview:</b> Acquire, train and trade your unique champions in the One-of-a-Kind play to earn game. Quest
          and Battle to test your party’s mettle. Choosing the right Brawlers and strategy in battle are key to victory.
          The leveling and Brawler creation algorithms ensure no two Brawler or battles are ever the same!
        </p>
        <p>
          <b>Brawler Generation:</b> Each Brawler is generated with a unique combination of class, clan, starting traits
          and abilities. Each Brawler has a unique value in each of the 3 Primary Attributes: Strength, Magic, and
          Dexterity, plus several special abilities. New Brawlers can be bought on this site for ETH, SKL, and GAME,
          while supplies last, or in game for BRAWL.
        </p>
        <p>
          <b>Arena Battles:</b> Test your strength against other players in the arena. Send your three Brawler team into
          battle! Your strategy choices in battle greatly influence the outcome. Win and earn fame and BRAWL tokens, and
          continue the battle. Lose, and you must return to fight another day. Win or lose, you can use the experience
          gained from combat to level up your Brawlers and improve their chances next time.
        </p>
        <p>
          <b>Level Up:</b> As your Brawler gains XP, you can level it up to unlock higher stats, new abilities, and
          better performance in the Arena. Or, shortcut the wait, and pay to train your Brawler with BRAWL tokens.
        </p>
        <p>
          <b>The Marketplace:</b> Buy and sell Brawlers in the online marketplace. Because each Brawler is unique and
          can be leveled / trained by each player, this is the world’s only truly one of a kind crypto-market!
        </p>
        <SectionBreak />
        <H2>BRAWLERS</H2>
        <p>
          Each brawler has four attributes, Strength, Magic, Dexterity, and Health. The first three are used in combat;
          Strength is weak against Magic, which is weak against Dexterity, which in turn is weak against Strength.
          Health determines the amount of punishment a Brawler can take before being knocked out. Brawlers also have
          special abilities
        </p>
        <Panel>
          <BrawlerGroup imageUrls={[waterKnight, doomRogue, airCleric]} />
        </Panel>
        <H4>Abilities</H4>
        <p>
          Abilities are gained by leveling up and can give advantages in Battle. Each Brawler has a % chance to activate
          one of their abilities each round. The % chance can increase as a player levels up each ability. Examples of
          abilities include:
        </p>
        <UL>
          <li>Relentless: Deals additional damage when attacking.</li>
          <li>Spiked Armor: When defending, deals damage to an opposing Strength Brawler.</li>
          <li>Power Attack: Increases Strength, Magic, and Dexterity when attacking.</li>
          <li>Healing Wave: Heals damage from all your Brawlers.</li>
          <li>Strength Hunter: Deals additional damage against Brawlers that focused on Strength.</li>
          <li>Dexterity Boost: Increases Dexterity when attacking.</li>
          <li>Volley of Arrows: Deals damage to all opposing Brawlers if you choose Dexterity.</li>
          <li>Clan Aura: Increased Strength, Magic, and Dexterity to Brawlers from the same Clan.</li>
          <li>Power Word: Kill: Small chance to instantly kill the opposing Brawler.</li>
        </UL>
        <H4>Clans</H4>
        <p>
          The five clans of Fungibalia are the Air Clan, Earth Clan, Fire Clan, Water Clan, and Doom Clan. Brawlers
          perform better in the Arena when they’re teamed up with Brawlers from their own clan.
        </p>
        <H4>Classes</H4>
        <p>
          The nine Brawler classes are Amazon, Barbarian, Cleric, Knight, Monk, Rogue, Warrior, Witch, and Wizard. Each
          class specializes in its own type of combat, and has a higher chance to gain class-specific special abilities.
        </p>
        <H4>Alignment</H4>
        <p>
          Brawlers can be Good, Evil, Lawful, or Chaotic. Brawlers perform better in the Arena when they’re teamed up
          with Brawlers with the same alignment.
        </p>
        <H4>Legendary Brawlers</H4>
        <p>
          Some Brawlers are Legendary. These Brawlers give their team increased Strength, Magic, and Dexterity, and gain
          50% extra XP from fights. There’s a maximum of 10,000 Legendary Brawlers, and Legendary Brawlers are only
          available in the Community Sale.
        </p>
        <H4>Level</H4>
        <p>
          Brawlers gain 20-40 XP for winning a fight at first level, and more at higher levels. Losing a fight gains you
          less XP. Leveling up from Level 1 to 2 requires 200 XP (7 wins on average). Leveling from Level 10 to 11
          requires 6,050 XP (33 wins on average), and leveling from 19 to 20 requires 20,000 XP (63 wins on average).
        </p>
        <p>
          Each time a Brawler levels up, they gain 1d6 to each stat, +2 to one stat, +1 to a second stat, and +1 to one
          ability. Additionally, each Brawler gains a new ability at levels 2, 5, 10, and 15.
        </p>
      </ContentSection>
      <SectionBreak />
      <KeyArt />

      <SectionBreak />
      <ContentSection>
        <H2>GAMEPLAY</H2>
        <H3>The Arena</H3>
        <p>
          The Arena provides on-chain play-to-earn gameplay, where you can pit your team of three Brawlers against the
          best of the best. A Rock Paper Scissors style battle determines the victor, with Strength (rock) weak against
          Magic (paper), which is in turn weak against Dexterity (scissors). Attributes and abilities are also critical,
          as is the composition of your team.
        </p>
        <p>
          To enter the arena battle, you pay 100 BRAWL. The block chain will then compute the fight, and show you who
          won and who lost. If you win, you earn the Bounty on the losing team. Bounties go up for every team your
          opponent has defeated, and are paid out from the play to earn pool. All participants in the arena also earn XP
          and Gold for each fight, win or lose.
        </p>
        <Panel>
          <BrawlerGroup imageUrls={[waterMonk, fireWarrior, earthWizard]} />
        </Panel>
        <p>
          When you enter the Arena, you’ll have roughly five minutes to complete your fight. If you win, your team of
          Brawlers enters the Arena, and becomes an opponent for new Arena entrants. You won’t earn any more BRAWL for
          this, but your team will continue to earn XP and Gold, making the arena a great way to level up. Once your
          team loses in the arena, your Brawlers need a rest. They’ll be on cooldown for the next 8 hours, during which
          time, they can’t fight in the Arena.
        </p>
        <H3>Free Battles</H3>
        <p>
          You can also enter a Free Battle. These don’t cost BRAWL to enter, allowing you to play to earn without paying
          to enter the Arena. Your Brawlers will also gain Gold and XP, and will go on cooldown whether you win or lose
          your fight.
        </p>
        <H3>Leaderboards</H3>
        <p>
          You can earn BRAWL and SKL tokens on the Block Brawlers leaderboards. The players with the most wins each week
          earn BRAWL tokens; check out{' '}
          <OutLink href={'https://play.blockbrawlers.com/leaderboards'}>the leaderboards</OutLink> to see the top
          players.
        </p>
        <SectionBreak />
        <H2>DUNGEONS &amp; PVE QUESTING [FUTURE]</H2>
        <p>
          Questing allows players to improve their Brawlers by gaining experience, levels, and new abilities. Select up
          to 5 Brawlers, then pay BRAWL to go on an on-chain quest. Brawlers gain XP for defeating monsters, but also
          risk damage and even death. Monsters make Speed/Magic/Dexterity attacks that compare against the same stat of
          the defender. Loser of each conflict takes damage.
        </p>
        <H3>Quest Play Loop</H3>
        <UL>
          <li>
            <b>Select a Quest:</b> Preset Quest Zones have different probabilities of monster types, levels of risk and
            rewards, giving players strategy in deciding which ones to adventure on.
          </li>
          <li>
            <b>Arrange Your Party:</b> Party order matters, dictating which Brawlers take damage most / first in
            attacks. You may want to buffer high-damage Brawlers in the back with high-health Brawlers in the front.
            Minimum and Maximum number of Brawlers, and Brawler level are Zone dependent.
          </li>
          <li>
            <b>Choose Your Strategy:</b> Each Brawler makes a combat strategy (usually its strongest trait) and monsters
            alternate making attacks on party members using the RPS system. Since different monsters in different quest
            zones will be weak and strong against different attacks, diversity of your team is valuable.
          </li>
        </UL>
        <SectionBreak />
        <H2>ECONOMICS</H2>
        <H3>Brawl Token</H3>
        <p>
          BRAWL is the token that powers the entire Block Brawlers ecosystem. BRAWL is used for running transactions on
          the Block Brawlers chain, buying and leveling up Brawlers, and entering the Arena. In addition, players earn
          BRAWL as play-to-earn rewards in the Arena. Pricing for these activities is as follows:
        </p>
        <UL>
          <li>Buy a Level 1 Brawler: 1000 BRAWL</li>
          <li>Buy a Level 5 Brawler: 2400 BRAWL</li>
          <li>Buy a Level 10 Brawler: 6400 BRAWL</li>
          <li>Buy a Level 20 Brawler (max level): 21,900 BRAWL</li>
          <li>Level up from Level 4 to Level 5: 500 BRAWL</li>
          <li>Level up from Level 9 to Level 10: 1000 BRAWL</li>
          <li>Enter the Arena: 100 BRAWL</li>
        </UL>
        <p>
          The total supply of BRAWL will be no more than 100M at the end of year 1, 150M at the end of year 2, and the
          maximum of 270M at the end of year 5. The token is distributed to early adopters (10%) and the player
          community (10%), play-to-earn rewards (25%), defi and liquidity incentives (25%), the team (20%) and an
          ecosystem development fund (10%). This token distribution is heavily influenced by the Axie Infinity AXS token
          distribution, which is a proven and well-supported model.
        </p>
        <p>
          BRAWL Tokens bought in the community sale are all unlocked immediately. Tokens bought by early adopters, and
          tokens in the team token pool vest over three years, with one third unlocked in each of the first three years.
          Liquidity, play-to-earn, and ecosystem fund tokens are unlocked on a sliding scale over six years, starting at
          25% in the first year, and reducing to 10% in year six. The net result is that the total supply of BRAWL is up
          to 94 million at the end of year one, 150 million at the end of year two, and 270 million at the end of year
          six. Circulating supply will be less in all cases, as much will be held in reserve, most notably in the case
          of liquidity, play-to-earn, and ecosystem fund tokens. Based on this, we expect circulating supply to reach a
          high of 75M in year one, and 120M in year two.
        </p>
        <p>
          Note that while BRAWL is an ERC-20 token on Ethereum mainnet, or on other Skale chains, it’s the native token
          for the Brawl Chain. This makes for a smooth user experience, as when you connect to the Brawl Chain, you’ll
          see your BRAWL balance in Metamask, in the same location you’d see your ETH balance on mainnet Ethereum. When
          you connect to mainnet, or to another Skale chain, you’ll see BRAWL as a regular ERC-20 token, so you can
          trade it on Ruby Exchange or Uniswap.
        </p>
        <H3>Liquidity Pools</H3>
        <p>
          Incentivized <OutLink href={'https://ruby.exchange'}>Ruby Exchange</OutLink> trading pairs will be set up for
          BRAWL. Additional pools and trading pairs will follow. Following on from these pools will be pools on other
          decentralized exchanges, and listings on centralized exchanges.
        </p>
        <H3>Marketplace</H3>
        <p>
          Block Brawlers will use the <OutLink href={'https://nftrade.com/'}>NFTrade</OutLink> marketplace on Skale for
          buying and selling Brawlers. When you sell a Brawler in the Marketplace, we take a 5% cut of that Brawler’s
          sale price.
        </p>
      </ContentSection>
      <SectionBreak />
      <CommunitySaleSection />
      <SectionBreak />
      <Roadmap />
      <SectionBreak />
      <TeamGrid />
      <SectionBreak />
    </PageFrame>
  );
}
