import { JsonRpcSigner } from '@ethersproject/providers';
import { targetChainId } from 'src/abis';
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ethereum: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    BinanceChain: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    web3: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    celo: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateWeb3Modal: any;
  }
}

export const switchToMainnet = async (signer: JsonRpcSigner): Promise<boolean> => {
  if (window.ethereum) {
    try {
      console.log('switch to mainnet');
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: `0x${targetChainId}`,
          },
        ], // you must have access to the specified account
      });
      const resultChainId = await signer.getChainId();
      return resultChainId === targetChainId;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  return false;
};
