import React from 'react';
import styled from 'styled-components/macro';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { theme } from 'src/theme';

export * from './About';
export * from './Buy';
export * from './Home';
export * from './ToS';

const StyledPageFrame = styled.div({
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: '60px 0 0',
});

const StyledPageColumn = styled.div({
  margin: 'auto',
  width: '100%',
  maxWidth: '800px',
  backgroundColor: theme.colors.blueBlack,
  paddingBottom: '40px',
  boxShadow: `0 0 8px 0px ${theme.colors.black}`,
});

export function PageFrame(props: { children: JSX.Element | JSX.Element[] }) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <StyledPageFrame>
        <StyledPageColumn>{props.children}</StyledPageColumn>
      </StyledPageFrame>
    </>
  );
}
