import React from 'react';
import { Burger } from './Burger';
import styled from 'styled-components/macro';
import logo from 'src/images/bb-logo.png';
import { theme } from 'src/theme';
import { Link } from 'react-router-dom';

const Nav = styled.nav({
  backgroundColor: theme.colors.blueBlack,
  color: theme.colors.fg,
  boxShadow: `0 0 8px 0px ${theme.colors.black}`,
  height: '60px',
  padding: '0 10px',
  display: 'flex',
  justifyContent: 'space-between',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
});

const NavLeft = styled.div({
  display: 'flex',
});

const LogoHolder = styled(Link)({
  cursor: 'pointer',
});

export const Logo = styled.img({
  height: '70px',
  margin: '5px 0 0 -5px',
});

export const Navbar = () => {
  return (
    <Nav>
      <NavLeft>
        <LogoHolder to="/">
          <Logo src={logo} alt="block-brawlers-logo" />
        </LogoHolder>
      </NavLeft>
      <Burger />
    </Nav>
  );
};
