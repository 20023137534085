import React from 'react';
import { ContentSection } from 'src/components';
import { PageFrame } from '.';

export function ToSPage() {
  return (
    <PageFrame>
      <ContentSection>
        <h1>Terms and Conditions of Service</h1>
        <p>Updated April 25 2022</p>
        <p>
          BY REGISTERING FOR AN ACCOUNT WITH US (your “Account”), USING THE SERVICES IN ANY WAY, CLICKING “I ACCEPT”
          BELOW, DOWNLOADING ANY SOFTWARE APPLICATION FROM US (as further defined in Section 2.2 below, “Software”), OR
          REGISTERING FOR OR PARTICIPATING IN ANY GAMES, YOU: (A) ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS AND
          CONDITIONS OF SERVICE AND ALL OBLIGATIONS AND RULES THAT MAY BE INCLUDED WITHIN EACH GAME IN WHICH YOU
          PARTICIPATE (“Rules”) (these Terms and Conditions of Service, the terms of any policy incorporated herein, and
          the Rules are collectively referred to as the “Terms”) IN THEIR ENTIRETY; (B) AGREE TO BE BOUND BY THE TERMS;
          AND (C) ARE AUTHORIZED AND ABLE TO ACCEPT THESE TERMS. If you don’t wish to be bound by the Terms, do not use
          the Services. Declining to accept these Terms means you will be unable to participate in any Games.
        </p>
        <h2>1. GENERAL TERMS</h2>
        <p>
          1.1. Changes to the Terms. We may amend, change, modify or revise the Terms at any time, and we may post a
          notice on our website at [www.blockbrawlers.com] (“Website”) of any material changes, and you can see when
          these Terms were last revised by referring to the “Updated” legend above. Your continued participation in
          Games and/or use of Software or Services means you accept any new or modified Terms. You are responsible for
          reviewing the Terms for any changes, so please check back here from time to time. You may not modify these
          Terms.
        </p>
        <p>
          1.2. United States (U.S.). To be eligible to participate in any Game or receive Services, and/or to download
          Software, you must: (a) be a natural person who is at least 18 years of age or older; (b) have the power to
          enter into a contract with Block Brawlers; (c) be physically located within the U.S. when accessing your
          Account and participating in Games; (d) be physically located within a U.S. state in which participation in
          the Game you select is unrestricted by that state’s laws; and (e) at all times abide by these Terms. If any
          one of these requirements is not met at any time, we may suspend your Account with or without notice.
        </p>
        <p>
          1.3. Non-U.S. To be eligible to participate in any Game or receive Services, and/or to download Software, you
          must: (a) be a natural person who is at least 18 years of age or older; (b) have the power to enter into a
          contract with Block Brawlers; (c) be physically located in a jurisdiction in which participation in the Game
          you select is permitted and unrestricted by that state or country’s laws; and (d) at all times abide by these
          Terms. If any one of these requirements is not met at any time, we may suspend or close your Account with or
          without notice.
        </p>
        <p>
          1.4. Your Account. You, as the holder of your Ethereum Account, are solely responsible for complying with
          these Terms, and only you are entitled to all benefits accruing thereto. You may not allow any other person to
          (i) access your Account; (ii) access Services or Software through your Account; or (iii) accept or use prizes,
          winnings and other representative of value (including without limitation digital trophies, virtual currency or
          virtual goods) (collectively “Winnings”).
        </p>
        <p>
          1.5. Personal Data: We do not collect your personal information, including personal location data. We may
          collect your email address at your request. By providing an email address, users authorize Block Brawlers to
          provide them with important announcements, relevant promotions, and other related communications. Users will
          always have the opportunity to opt out of these communications at any time.
        </p>
        <h2>2. SERVICES AND SOFTWARE</h2>
        <p>
          2.1. Our Services. We may, with or without notice to you: (1) modify, suspend or terminate your access to the
          Website, Services and/or Software for any reason without liability; and (2) interrupt the operation of the
          Website, Services and/or Software as necessary to perform maintenance, error correction, or other work. We may
          suspend the account of any user who violates, or whom we reasonably believe may be in violation of or will
          violate, these Terms, at any time without notice and without liability.
        </p>
        <p>
          2.2. Software. If you wish to participate in Games or receive Services, you may be required to first download
          certain proprietary Block Brawlers software and/or mobile applications (together with the content included
          therein, any associated documentation, and any application program interfaces, license keys, and patches,
          updates, upgrades, improvements, enhancements, fixes and revised versions of any of the foregoing, is
          collectively “Software”). If you do not download the Software, you will not be able to participate in
          Competitions or receive relevant Services. Your use of the Software is subject to these Terms. We license the
          Software to you under Section 9.2.
        </p>
        <p>
          2.3. Remote Access and Updates. If and when we update the Software or deploy patches, updates, and
          modifications to the Software, we may do so through remote access of your Device without your knowledge. You
          hereby consent to these activities. You acknowledge that if we cannot remotely access your Device, then the
          Software may no longer work, and this may prevent you from participating in Games or otherwise receiving
          Services. Our access to your Device will be limited only for purposes of providing support or updating the
          Software, and is governed by the terms of our Privacy Policy.
        </p>
        <p>
          2.4. Beta Releases. For any Service that is identified by us as a “beta” version (“Beta Service”), you
          acknowledge and agree that a Beta Service may contain more or fewer features than the final release of the
          Service. We reserve the right, in our sole discretion, not to release a final release of a Beta Service or to
          alter its features, specifications, capabilities, functions, licensing terms, release dates, general
          availability or other characteristics. Beta Services may not be suitable for production use and may contain
          errors affecting proper operation and functionality.
        </p>
        <p>
          2.5. Third Party Sites. You may be able to access third-party websites or services via the Software, Services
          or Website. We are not responsible for third-party websites, services, or content available through those
          third-party services. You are solely responsible for your dealings with third-parties (including advertisers
          and game developers). Your use of third-party software, websites or services may be subject to that
          third-party’s terms and conditions.
        </p>
        <p>
          2.6. We neither own nor control MetaMask, Coinbase, Google Chrome, the Ethereum network, or any other third
          party site, product, or service that you might access, visit, or use for the purpose of enabling you to use
          the various features of the App. We will not be liable for the acts or omissions of any such third parties,
          nor will we be liable for any damage that you may suffer as a result of your transactions or any other
          interaction with any such third parties.
        </p>
        <h2>3. COMPLIANCE WITH LAWS</h2>
        <p>
          3.1. Prohibited US States/Countries. You acknowledge that various rules, regulations and laws addressing
          sweepstakes, contests, and tournaments with entry fees and/or prizes govern your participation in Competitions
          (“Gaming Laws”), and that Gaming Laws are set up by each individual US state, country, territory, or
          jurisdiction. Therefore, we DO NOT promote Games to users in any state in which such Game violates its Gaming
          Laws (“Prohibited Jurisdiction”), and if you are located in any Prohibited Jurisdiction then you may not
          participate in such Games. In the United States, Prohibited Jurisdictions, as of the “Updated” date above,
          include: Arizona, Arkansas, Connecticut, Delaware, Florida, Louisiana, Maryland, Montana, South Carolina,
          South Dakota, and Tennessee. It is your responsibility to determine whether the state, country, territory or
          jurisdiction in which you are located is a Prohibited Jurisdiction. We reserve the right (but have no
          obligation) to monitor the location from which you access Services, and we may block access from any
          Prohibited Jurisdiction. Each time you log in to participate in a Game, you must accurately confirm the
          location from which you are playing.
        </p>
        <p>
          3.2. Additional Laws. In addition to Gaming Laws, you are also subject to all municipal, state and federal
          laws, rules and regulations of the city, state and country in which you reside and from which you access and
          use Services, including without limitation U.S. export laws (together with Gaming Laws, the “Applicable
          Laws”). You are solely responsible for your compliance with all Applicable Laws. Access to Competitions may
          not be legal for some or all residents of, or persons present in, certain jurisdictions. SERVICES AND GAMES
          ARE VOID WHERE PROHIBITED OR RESTRICTED BY APPLICABLE LAWS. Your participation in Games is at your own risk,
          and you agree not to hold us responsible or liable if Applicable Laws restrict or prohibit your access or
          participation.
        </p>
        <p>
          3.3. Legal Disclaimers. WE MAKE NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, AS TO THE LAWFULNESS OF
          YOUR PARTICIPATING IN ANY GAME OR USE OF SERVICES, NOR SHALL ANY PERSON AFFILIATED, OR CLAIMING AFFILIATION,
          WITH US HAVE AUTHORITY TO MAKE ANY SUCH REPRESENTATIONS OR WARRANTIES.
        </p>
        <h2>4. YOUR REPRESENTATIONS AND WARRANTIES TO US</h2>
        <p>
          You represent and warrant to us that (1) you have the right, authority, and capacity to agree to these Terms,
          to register for an Account, and to participate in those Games for which you register; and (2) you will comply
          with these Terms when participating in Games, receiving Services, and/or using Software; and (3) all
          information you supply to us is complete, accurate and current (and knowingly submitting incomplete or
          inaccurate information, or failing to update and maintain current, complete and accurate information, may
          result, without limitation, in immediate termination of your Account).
        </p>
        <h2>5. YOUR INDEMNIFICATION OF US</h2>
        <p>
          You will, at your own cost and expense, indemnify and hold us and our directors, officers, employees and
          agents harmless from and against any and all claims, disputes, liabilities, judgments, settlements, actions,
          debts or rights of action, losses of whatever kind, and all costs and fees, including reasonable legal and
          attorneys’ fees, arising out of or relating to (i) your breach of these Terms; (ii) any use of your Account,
          the Website, the Software and the Services by any person including yourself; (iii) your violation of
          Applicable Laws; and/or (iv) your negligence or misconduct; and, if we instruct you in writing, you will, at
          your cost and expense, defend us from any of the foregoing using counsel reasonably acceptable to us.
        </p>
        <h2>6. PRIVACY</h2>
        <p>
          6.1. Privacy Policy. We are committed to your privacy, and our Privacy Policy, the terms of which are
          incorporated into these Terms as if set forth in their entirety, explains the policies put in place and used
          by us to protect your privacy as you visit the Website, participate in Games, download and use the Software
          and receive Services, and its terms are made a part of these Terms by this reference. We receive, store and
          use all information that you submit to the Website and all information you submit in registering for and
          participating in Services, in accordance with the Privacy Policy, so please read it carefully. We also collect
          anonymous aggregated and/or statistical data reflecting your use of the Website and Services and may use such
          data for tracking, reporting and other activities in connection with our business, also all in accordance with
          the Privacy Policy. We will not intentionally disclose any personally identifying information about you
          (including information submitted in creating an Account, your name, your email address, information obtained
          by the Website from cookies, and information regarding your IP address) to third parties without your consent
          except (1) where expressly specified in these Terms, (2) where expressly specified in the Privacy Policy,
          and/or (3) where we, in good faith, believe such disclosure is necessary to comply with Applicable Laws, to
          enforce these Terms against you, or to help prevent a loss of life or physical injury or crime.
        </p>
        <p>
          6.2. Communications With You. As also detailed in this Privacy Policy, we may use emails, text messages, and
          push notifications to notify you when you win Games, when a Game you have entered has completed, and to let
          you know of special promotions, events and policy changes. We may also communicate with you via email, text
          message, push notification or chat for any other purpose relating to Services or Software. We or our
          representatives may monitor all communications made by or received by you while using the Website and
          Services. If you do not wish to receive these communications from us, you may opt out, but you acknowledge
          that opting out may result in your inability to participate in Games or receive Services.
        </p>
        <p>
          6.3. Device Information. Using the Software and Services requires an Internet connection to our servers, and
          we may need to collect certain information from you and your Internet-enabled device (“Device”) in order to
          make the Software and Services available to you, such as hardware system profile data, internet connection
          data and any other data related to the operation of the Service from any Device that logs onto the Service
          using your Account. We will use this information in accordance with the Privacy Policy.
        </p>
        <p>
          6.4. Warning. Please take care in sending us sensitive information because third parties can unlawfully
          intercept or access transmissions or private communications between you and us, and you acknowledge that
          internet transmissions are never completely private or secure.
        </p>
        <p>
          6.5. Promotional Activities. By registering for an Account, you allow us to publicly display your username and
          tournament records, and to use this information for any purpose. By using the Services, you allow us to print,
          publish, broadcast and use, worldwide, in any media and at any time, your name, picture, voice, likeness,
          and/or any biographical information that you submit to us (“Biographical Information”) for our own
          promotional, marketing or related business purposes, without compensation to you. However, we will never sell
          your Biographical Information without your prior written consent, and our use of your personally identifiable
          information is always governed by our Privacy Policy.
        </p>
        <p>
          6.6. Children. The Services will not knowingly accept personal information from anyone under 18 years old. If
          you believe that a child under 18 has gained access to the Services, please contact us. We do not sell
          products or services for purchase by minors.
        </p>
        <h2>7. ACCEPTABLE USE POLICY</h2>
        <p>
          7.1. Rules of Conduct. You are personally responsible for your use of Services and Software, and while using
          Services and Software you must conduct yourself in a lawful and respectful manner in accordance with our rules
          of conduct below. We may temporarily or permanently ban users who violate these rules, or who abuse email
          communications, support communications, or the community purpose of any message board areas, as determined by
          us in our sole discretion. We reserve the right to disable a player’s ability to upload profile photos or edit
          their username at any time.
        </p>
        <ul>
          <li>
            Profanity, obscenities, or the use of **asterisks** or other “masking” characters to disguise such words, is
            not permitted.
          </li>
          <li>
            You may not use or upload obscene, lewd, slanderous, pornographic, abusive, violent, insulting, indecent,
            threatening and harassing language of any kind, as determined by us in our sole discretion.
          </li>
          <li>Service usernames will be displayed as ‘Your Name’; impersonating other players is not allowed.</li>
          <li>
            Do not share personal information (your name, phone number, home address, and password) with other users.
          </li>
          <li>Do not transmit or upload any copyrighted, or trademarked materials in messages or postings.</li>
          <li>
            Information disclosed in chat rooms, message boards, gameplay dialogue or via eMessages is revealed to the
            public, and we are not responsible for information you choose to disclose to others.
          </li>
          <li>
            Advanced fonts, java, tables, html, or other programming codes or commands are not allowed in messages.
          </li>
          <li>
            You may not attempt to participate in any Service by means of automatic, macro, programmed, or similar
            methods.
          </li>
          <li>You may not commit fraud with regard to any Service.</li>
          <li>
            You may not attempt to impersonate or deceive another user for the purposes of illicitly obtaining cards,
            passwords, account information etc. (aka “scamming”).
          </li>
          <li>
            You may not make any commercial use of any of the information provided on the Website or through the
            Services nor make any use of the Website or Services for the benefit of a business.
          </li>
        </ul>
        <p>
          7.2. Your Content. You acknowledge that the Service is a passive conduit for user content and that we do not
          pre-screen user content or communications. We do not control, verify or pay for any user content or
          communications. We do not endorse, and specifically disclaim any responsibility or liability for, any publicly
          posted content. In addition, we may terminate your access to any public forums at any time, without notice,
          for any reason whatsoever, and/or delete, move or edit content submitted publicly, in whole or in part. You
          may only upload, send, and receive messages and material that is related to the subject matter of the public
          forums, complies with Applicable Laws, and conforms to any additional terms of service we post in the public
          forums. You may not upload to, distribute, or otherwise publish any content, information, or other material
          that (a) violates or infringes the copyrights, patents, trademarks, service marks, trade secrets, or other
          proprietary rights of any person; (b) is libelous, threatening, defamatory, obscene, indecent, pornographic,
          or could give rise to any civil or criminal liability under U.S. or international law; or (c) includes any
          bugs, viruses, worms, trap doors, Trojan horses or other harmful code or properties. Submissions or opinions
          expressed by users are that of the individual expressing such submission or opinion and may not reflect our
          opinions. Subject to the foregoing, we may edit, refuse to post, or to remove any information or materials
          that you submit to us, in our sole discretion. You may not use a false email address, pretend to be someone
          other than yourself or otherwise mislead us or third parties as to the origin of your submissions or content.
          We may, but shall not be obligated to, remove or edit any of your submissions or content for any reason.
        </p>
        <p>
          7.3. Cheating, Fraud, and Abuse. In accessing or participating in Services or using the Software, you
          represent and warrant to us that you will not engage in any activity that interrupts or attempts to interrupt
          the operation of the Services or Software. Anyone who engages in, participates in, or displays behavior that
          may be interpreted, in our sole discretion, as unfair methods in participating in Services or using the
          Software, including but not limited to, the opening and/or use of multiple accounts, the use of unauthorized
          or altered software or hardware to assist play (e.g., bots, bot nets, and collusion with bots), intentionally
          poor play in certain games to achieve competitive advantage, collusion with other players, deliberate transfer
          of money between accounts (e.g., “money laundering”), harassment of other participants, posting objectionable
          material, breach of these Terms, breach of security of your Account or, or any other act (whether through the
          use of automated technology or otherwise) that unfairly alters your chance of winning or constitutes the
          commission of fraud (collectively, “Abuse”), you will be subject to immediate sanction (as determined by us in
          our sole discretion), which may include, without limitation: (1) immediate termination of your Account and
          blocking of your access to the Website and Services; (2) any Winnings that you may otherwise have been
          entitled to receive shall be void and forfeited; and (3) any Winnings received by you shall be subject to
          disgorgement and/or recoupment. In addition to the foregoing, we reserve the right to disclose or report any
          money laundering similar illegal activity to law enforcement and regulatory authorities. Without limiting our
          other available remedies, we may institute or seek any injunctive relief, civil and/or criminal proceedings
          against you and/or any of your co-conspirators arising out of or related to your commission of Abuse,
          including without limitation recovering all of our fees and expenses (including reasonable attorneys’ fees) in
          connection with such efforts.
        </p>
        <p>
          7.4. Hacking, Tampering, or Unauthorized Access. Any attempt to gain unauthorized access to our systems or any
          other user’s account, interfere with procedures or performance of Services, Software or the Website, or
          deliberately damage or undermine the Services or Software is subject to civil and/or criminal prosecution and
          will result in immediate termination of your Account and forfeiture of your Winnings. You acknowledge that we
          are not responsible for any damage, loss, or injury resulting from hacking, tampering, or other unauthorized
          access or use of Services or your Account.
        </p>
        <p>
          7.5. Restrictions. Any use, reproduction or redistribution of the Service, Software, or related products or
          services (including without limitation, Digital Assets, as defined in Section 9.3) not expressly authorized by
          these Terms is expressly prohibited. You may not engage in, or assist others to engage in, conduct that would
          damage or impair our property including, without limitation: (a) copying, distributing, transmitting,
          displaying, performing, framing, linking, hosting, caching, reproducing, publishing, licensing, or creating
          derivative works from any information, software, products or services obtained from us; (b) providing
          unauthorized means through which others may use Services such as through server emulators; (c) taking actions
          that impose an unreasonable or disproportionately large load on our or our suppliers’ network infrastructure,
          or that could damage, disable, overburden, or impair our Websites or Services; (d) interfering with any other
          party’s use and enjoyment of Services and/or Software (including cheating) or the Website; and/or (e)
          attempting to gain unauthorized access to third party accounts, the Service, or Software.
        </p>
        <h2>8. PAID GAMEPLAY AND FEES</h2>
        <p>
          8.1. Where allowable by law, we provide access to decentralized paid gameplay services hosted on the Ethereum
          blockchain. Your participation in any paid gameplay services is a contract between yourself, any opponents,
          and the gameplay provider. Any gameplay hosted by computer code on a blockchain (a “Smart Contract”) is
          entirely managed by that smart contract, and those services are provided as is. You should carefully review
          all Smart Contract code before utilizing a paid gameplay service.
        </p>
        <p>
          8.2. Fees. If there are Fees charged to your Account, you agree to pay those Fees. All Fees must be prepaid,
          and are non-refundable. You are fully responsible and liable for all charges, deposits and withdrawals made
          under your Account, including any unauthorized charges, deposits or withdrawals. We may change the price of
          Services at any time, but no price change will affect your past purchases.
        </p>
        <p>
          8.3. If you elect to purchase, trade, or play games on the App, or with or from other users via the App, any
          financial transactions that you engage in will be conducted solely through the Ethereum network. We will have
          no insight into or control over these payments or transactions, nor do we have the ability to reverse any
          transactions. With that in mind, we will have no liability to you or to any third party for any claims or
          damages that may arise as a result of any transactions that you engage in via the App, or using the Smart
          Contracts, or any other transactions that you conduct via the Ethereum network.
        </p>
        <p>
          8.4. Ethereum requires the payment of a transaction fee (a “Gas Fee”) for every transaction that occurs on the
          Ethereum network. The Gas Fee funds the network of computers that run the decentralized Ethereum network. This
          means that you will need to pay a Gas Fee for each transaction that occurs via the App.
        </p>
        <p>
          8.5. In addition to the Gas Fee, each time you utilize a Smart Contract to conduct a transaction with another
          user via the App, you authorize us to collect a commission of a percentage of the total value of that
          transaction (each, a “Commission”). You acknowledge and agree that the Commission will be transferred directly
          to us through the Ethereum network as part of your payment.
        </p>
        <p>
          8.6. As between us, you will be solely responsible to pay any and all sales, use, value-added and other taxes,
          duties, and assessments (except taxes on our net income) now or hereafter claimed or imposed by any
          governmental authority (collectively, “Taxes”) associated with your use of the App (including, without
          limitation, any Taxes that may become payable as the result of your ownership, transfer, or gameplay of Block
          Brawlers). Except for income taxes levied on Block Brawlers, you: (i) will pay or reimburse us for all
          national, federal, state, local or other taxes and assessments of any jurisdiction, including value added
          taxes and taxes as required by international tax treaties, customs or other import or export taxes, and
          amounts levied in lieu thereof based on charges set, services performed or payments made hereunder, as are now
          or hereafter may be imposed under the authority of any national, state, local or any other taxing
          jurisdiction; and (ii) shall not be entitled to deduct the amount of any such taxes, duties or assessments
          from payments made to us pursuant to these Terms.
        </p>
        <h2>9. PROPRIETARY RIGHTS</h2>
        <p>
          9.1. Your Content. Subject to these Terms, you grant to us a worldwide, perpetual, unrestricted, royalty-free
          license to use, copy, modify, distribute, publish, perform, transmit, and display any and all communications,
          materials, content and information that you submit to us, whether directly or through the Website, Services or
          Software (“Content”), and waive any moral rights you may have in Content. Subject to these Terms, any
          communication or material you transmit to us, including any data, questions, comments, suggestions, or the
          like, will be treated by us as non-confidential and non-proprietary. Subject to these Terms, we may use
          Content for any purpose, without any compensation, accounting or other liability or obligation to you. If you
          use or share Content in a way that infringes others’ copyrights, trademarks, other intellectual property
          rights, or privacy rights, you are breaching these Terms. You represent and warrant to us that for the
          duration of these Terms you have (and will have) all the rights necessary for the Content you upload or share
          on the services and that the use of the Content, as contemplated in this Section will not violate any
          Applicable Laws. If your Account is cancelled or terminated, we may permanently delete your Content from our
          servers and we have no obligation to return Content to you.
        </p>
        <p>
          9.2. Software License. Subject to these Terms, we grant to you a personal, nonexclusive, limited,
          non-transferable, non-assignable, non-sublicensable, limited license to install and run the Software, in
          object code format only, on a Device owned or controlled by you, solely for the purpose of accessing and using
          the Services in accordance with these Terms, and solely for so long as your Account is open. You acknowledge
          that you are receiving licensed rights only. You may not network the Software among devices. You may not
          directly or indirectly, or authorize any person or entity to: (i) reverse engineer, decompile, disassemble,
          re-engineer or otherwise create or attempt to create or permit, allow, or assist others to create the source
          code of the Software, or its structural framework; (ii) create derivative works of the Software; (iii) use the
          Software in whole or in part for any purpose except as expressly provided herein; or (iv) disable or
          circumvent any access control or related device, process or procedure established with respect to the
          Software. You acknowledge that you have access to sufficient information such that you do not need to reverse
          engineer the Software in any way to permit other products or information to interoperate with the Software.
          You are responsible for all use of the Software that is under your possession or control.
        </p>
        <p>
          9.3. Digital Assets. Some Services and Software may allow you to create digital objects, such as avatars. Such
          digital objects, in addition to any digital or virtual objects or assets we assign to your Account, virtual
          trophies or virtual goods, are collectively referred to as “Digital Assets”. We acknowledge that, where such
          assets are created on the Ethereum blockchain, or another public and distributed blockchain, you own such
          asset, and it may be used in our Games only with our permission. You acknowledge that when a Digital Assets is
          created through the Software and/or Services, we solely and exclusively own that Digital Asset. To the extent
          we do not automatically own any Digital Asset, you hereby irrevocably, expressly and automatically assign to
          us, in perpetuity, all right, title and interest in and to such Digital Assets, including, without limitation,
          all copyrights, patent rights, trade secrets, trademarks, moral rights and all other applicable proprietary
          and intellectual property rights throughout the world. If you have any rights to Digital Assets that cannot
          (as a matter of law) be assigned to us in accordance with the foregoing, you unconditionally and irrevocably:
          (i) waive the enforcement of such rights against us; and (ii) grant to us an exclusive, irrevocable,
          perpetual, worldwide, royalty-free license (a) to reproduce, create derivative works of, distribute, publicly
          perform, publicly display, digitally perform, and otherwise use and exploit such Digital Assets, (b) to use,
          make, have made, sell, offer to sell, import, and otherwise exploit any product or service based on,
          embodying, incorporating, or derived from Digital Assets, and (c) to exercise any and all other present or
          future rights not yet known in Digital Assets. Subject to these Terms, we grant you a limited license to use
          Digital Assets through your own Account solely for purposes and in furtherance of your use of Services.
        </p>
        <p>
          9.4. Ownership. All content of the Website, the Software, Services, and all Block Brawlers products and
          services, and all Block Brawlers logos, symbols, expansion names and symbols, play level symbols, trade dress
          or “look and feel”, and all Digital Assets, and all derivative works or modifications of any of the foregoing,
          and all related and underlying intellectual property (including without limitation patents, trademarks, trade
          secrets and copyrights), are our sole and exclusive property (the “Block Brawlers Intellectual Property”). We
          reserve all rights not expressly granted herein. Except as expressly set forth herein: no right or license is
          granted hereunder, express or implied or by way of estoppel, to any intellectual property rights and your use
          of Services and/or Software does not convey or imply the right to use the Services or Software in combination
          with any other information or products.
        </p>
        <p>
          9.5. For the sake of clarity, you understand and agree: (i) that your “purchase” of a Block Brawler, whether
          via the App or otherwise, does not give you any rights or licenses in or to any Block Brawlers Intellectual
          Property (including, without limitation, our copyright in and to the art and drawings associated with that
          Block Brawler) other than those expressly contained in these Terms; and (ii) that you do not have the right to
          reproduce, distribute, or otherwise commercialize any elements of the Block Brawlers Intellectual Property
          (including, without limitation, our copyright in and to the art and drawings associated with that Block
          Brawler) in any way without our prior written consent in each case, which consent we may withhold in our sole
          and absolute discretion.
        </p>
        <h2>10. TERM AND TERMINATION</h2>
        <p>
          These Terms apply to you and to us from the date that you accept them as provided above, until termination of
          your Account (whether by deactivation, cancellation, closure, expiration or termination by you or us). Upon
          termination of your Account, you must immediately discontinue use of the Services and the Software and your
          Account and promptly uninstall and delete all copies of the Software. Immediately upon termination of your
          Account, all license and rights granted to you under these Terms automatically terminate and you shall
          automatically forfeit the right to use Digital Assets. Your obligation to pay accrued Fees will survive any
          termination of these Terms. Any and all terms and conditions within these Terms which should, by their nature,
          survive termination of these Terms, will survive such termination (including without limitation Sections 3.3,
          4, 5, 6, 8.6, and 9 through 14 (inclusive).
        </p>
        <h2>11. DISCLAIMERS</h2>
        <p>
          We strive to keep Services up and running; however, all online services suffer occasional disruptions and
          outages, we are not responsible or liable for any disruption or loss you may suffer as a result. You should
          regularly backup content that you store on the Services.
        </p>
        <p>
          11.1. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, ALL SERVICES, PRODUCTS, INFORMATION AND DATA
          PROVIDED OR MADE AVAILABLE BY US (INCLUDING WITHOUT LIMITATION DIGITAL ASSETS AND SOFTWARE) ARE “AS IS” AND
          WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, AND YOU ASSUME THE ENTIRE RISK WITH
          RESPECT THERETO. WE MAKE NO REPRESENTATION, WARRANTY OR GUARANTEE THAT SERVICES, YOUR ACCOUNT, SOFTWARE, THE
          WEBSITE AND/OR DIGITAL ASSETS WILL BE SECURE, VIRUS-FREE, UNINTERRUPTED OR ERROR-FREE, OR THAT THE SAME WILL
          FUNCTION PROPERLY IN COMBINATION WITH ANY THIRD PARTY COMPONENT, TECHNOLOGY, HARDWARE, SOFTWARE, OR SYSTEM.
        </p>
        <p>
          11.2. YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET,
          AND AGREE THAT WE HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR GROSS
          NEGLIGENCE.
        </p>
        <p>
          11.3. WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU INCUR AS THE RESULT OF YOUR USE OF THE
          ETHEREUM NETWORK OR THE METAMASK ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS
          ARISING FROM: (A) USER ERROR, SUCH AS FORGOTTEN PASSWORDS OR INCORRECTLY CONSTRUED SMART CONTRACTS OR OTHER
          TRANSACTIONS; (B) SERVER FAILURE OR DATA LOSS; (C) CORRUPTED WALLET FILES; (D) UNAUTHORIZED ACCESS OR
          ACTIVITIES BY THIRD PARTIES, INCLUDING BUT NOT LIMITED TO THE USE OF VIRUSES, PHISHING, BRUTEFORCING OR OTHER
          MEANS OF ATTACK AGAINST THE APP, ETHEREUM NETWORK, OR THE METAMASK ELECTRONIC WALLET.
        </p>
        <p>
          11.4. BLOCK BRAWLERS ARE INTANGIBLE DIGITAL ASSETS THAT EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD
          MAINTAINED IN THE ETHEREUM NETWORK. ALL SMART CONTRACTS ARE CONDUCTED AND OCCUR ON THE DECENTRALIZED LEDGER
          WITHIN THE ETHEREUM PLATFORM. WE HAVE NO CONTROL OVER AND MAKE NO GUARANTEES OR PROMISES WITH RESPECT TO SMART
          CONTRACTS.
        </p>
        <p>
          11.5. BLOCK BRAWLERS IS NOT RESPONSIBLE FOR LOSSES DUE TO BLOCKCHAINS OR ANY OTHER FEATURES OF THE ETHEREUM
          NETWORK OR THE METAMASK ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO LATE REPORT BY DEVELOPERS OR
          REPRESENTATIVES (OR NO REPORT AT ALL) OF ANY ISSUES WITH THE BLOCKCHAIN SUPPORTING THE ETHEREUM NETWORK,
          INCLUDING FORKS, TECHNICAL NODE ISSUES, OR ANY OTHER ISSUES HAVING FUND LOSSES AS A RESULT.
        </p>
        <p>
          11.6. We are not responsible or liable for any damage, loss, or injury resulting from, relating to or arising
          out of (1) use, access, or attempted use or access of Services, Digital Assets, the Software or the Website;
          (2) downloading any information from the Software, Services or Website; and/or (3) violations of these Terms
          by other users. We have no responsibility to enforce these terms for the benefit of any user.
        </p>
        <p>
          11.7. Some states do not allow the disclaimer of implied warranties; as such the foregoing disclaimer may not
          apply to you in its entirety.
        </p>
        <h2>12. LIMITATIONS OF LIABILITY</h2>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, NEITHER WE, NOR OUR SUPPLIERS OR LICENSORS, WILL BE
          LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL (INCLUDING, WITHOUT
          LIMITATION, LOST PROFITS, LOST DATA OR LOSS OF GOODWILL), OR INCIDENTAL DAMAGES, ARISING OUT OF OR RELATING TO
          THESE TERMS, THE WEBSITE, OR ANY INFORMATION, SERVICES, PRODUCTS OR SOFTWARE MADE AVAILABLE OR ACCESSIBLE TO
          YOU, WHETHER BASED ON A CLAIM OR ACTION OF CONTRACT, WARRANTY, NEGLIGENCE, STRICT LIABILITY, OR OTHER TORT,
          BREACH OF ANY STATUTORY DUTY, INDEMNITY OR CONTRIBUTION, OR OTHERWISE, EVEN IF WE OR OUR THIRD PARTY SUPPLIERS
          OR LICENSORS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH LIABILITY.
        </p>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, OUR MAXIMUM LIABILITY TO YOU ARISING OUT OF OR IN ANY
          WAY CONNECTED TO THESE TERMS SHALL NOT EXCEED U.S. $50.00. THE EXISTENCE OF ONE OR MORE CLAIMS BY YOU WILL NOT
          INCREASE OUR LIABILITY. IN NO EVENT SHALL OUR SUPPLIERS OR LICENSORS HAVE ANY LIABILITY ARISING OUT OF OR IN
          ANY WAY CONNECTED TO OUR PRODUCTS, INFORMATION OR SERVICES.
        </p>
        <p>
          Certain jurisdictions do not allow limitations of liability for incidental, consequential or certain other
          types of damages; as such, the limitations and exclusions set forth in this Section may not apply to you.
        </p>
        <h2>13. DISPUTE RESOLUTION AND ARBITRATION</h2>
        <p>
          We believe that forced arbitration agreements, while beneficial to a company, are unethical. We’d much rather
          work any disputes out directly with you - you can get in touch with us via Discord.
        </p>
        <p>
          13.1 Lawyers. No, our lawyers didn’t like that we deleted their forced arbitration language and put this in.
        </p>
        <p>
          13.2 Disputes. Any dispute You understand and agree that the cancellation of your Account or a particular
          subscription is your sole right and remedy with respect to any dispute with Block Brawlers, including any
          dispute related to, or arising out of: (1) any term of this Terms of Service or Block Brawlers’s enforcement
          or application of this Terms of Service; (2) the Content and Entitlements available through Block Brawlers
          Services or any change in Content or Entitlements provided through Block Brawlers Services; (3) your ability
          to access and/or use Block Brawlers Services and/or any Content or Entitlements thereon; or (4) the amount or
          type of fees, surcharges, applicable taxes, billing methods, or any change to the fees, applicable taxes,
          surcharges or billing methods for Block Brawlers Services and/or any Content or Entitlements thereon.
        </p>
        <p>
          13.3. Equitable Relief. You agree that we would be irreparably damaged if these Terms were not specifically
          enforced. Therefore, in addition to any other remedy we may have at law, and notwithstanding our agreement to
          arbitrate Disputes, we are entitled without bond, other security, or proof of damages, to seek appropriate
          equitable remedies with respect to your violation of these Terms in any court of competent jurisdiction.
        </p>
        <p>
          13.4 LANGUAGE OF THE TERMS: If we provide a translated version of these Terms, or any other Block Brawlers
          policy, it is for informational purposes only. If the translated version means something different than the
          English version, then the English meaning will be the one that applies.
        </p>
        <h2>14. MISCELLANEOUS</h2>
        <p>
          These Terms constitute the entire agreement between you and us pertaining to the subject matter hereof and
          supersede all prior or other arrangements, understandings, negotiations and discussions, whether oral or
          written. These Terms cannot be modified by you, and may only be modified by us as provided above. Section
          titles in these Terms are for reference only and have no legal effect. We may assign these Terms, in whole or
          in part, at any time without notice to you. You may not assign these Terms or transfer any rights to use the
          Services or Software.
        </p>
        <p>
          14.1 Remedies. You agree that this Terms of Service is not intended to confer and does not confer any rights
          or remedies upon any person other than the parties to this Terms of Service. You also understand and agree
          that this Terms of Service, including Block Brawlers’s enforcement of those policies, are not intended to
          confer, and do not confer, any rights or remedies upon any person. No right or remedy of ours shall be
          exclusive of any other, whether at law or in equity, including without limitation damages injunctive relief,
          attorneys’ fees and expenses.
        </p>
        <p>
          14.2 Severability. If any part of this Terms of Service is held invalid or unenforceable, that portion shall
          be interpreted in a manner consistent with applicable law to reflect, as nearly as possible, the original
          intentions of Block Brawlers, and the remaining portions shall remain in full force and effect.
        </p>
        <p>
          14.3 Waiver. The failure of Block Brawlers to exercise or enforce any right or provision of this Terms of
          Service will not constitute waiver of such right or provision. Any waiver of any provision of this Terms of
          Service will be effective only if in a writing signed by Block Brawlers.
        </p>
        <p>
          14.4 Notices. You consent to our providing you notifications about the Services or information the law
          requires us to provide via email to the address that you specified when you created your Account. Notices
          emailed to you will be deemed given and received when the email is sent. If you do not consent to receive
          notices electronically, you must close your Account. These Terms are solely for your and our benefit, and not
          for the benefit of any other person, except for our successors and assigns.
        </p>
        <p>
          14.5 Governing Law. The laws of the State of Washington, excluding its conflicts-of-law rules, govern this
          Terms of Service and your Account(s). As noted above, your conduct may also be subject to other local, state,
          national, and international laws.
        </p>
      </ContentSection>
    </PageFrame>
  );
}
