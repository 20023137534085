import React from 'react';
import { Link } from 'react-router-dom';
import { SocialLinks } from 'src/calls';
import { Paths } from 'src/nav/paths';
import { theme } from 'src/theme';
import styled from 'styled-components/macro';
import { ContactSection } from './ContactSection';

const StyledFooter = styled.footer({
  backgroundColor: theme.colors.blueBlack,
  color: theme.colors.mg,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
});

const FooterRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  width: '100%',
  maxWidth: '300px',
  margin: '20px auto 0',
});

const FooterCol = styled.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '150px',
});

const FooterLink = styled(Link)({
  color: theme.colors.blueDark,
  padding: '0 10px',
  textTransform: 'uppercase',
  textUnderlineOffset: '8px',
  textDecoration: 'none',
  fontWeight: 700,
  ['&:hover']: {
    textDecoration: 'none',
  },
  [theme.mediaQueries.small]: {
    ['&:hover']: {
      textDecoration: 'underline',
    },
  },
});

const FooterOutLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})({
  color: theme.colors.blueDark,
  padding: '0 10px',
  textTransform: 'uppercase',
  textUnderlineOffset: '8px',
  textDecoration: 'none',
  fontWeight: 700,
  ['&:hover']: {
    textDecoration: 'none',
  },
  [theme.mediaQueries.small]: {
    ['&:hover']: {
      textDecoration: 'underline',
    },
  },
});

export function Footer() {
  return (
    <StyledFooter>
      <ContactSection />
      <FooterRow>
        <FooterCol>
          <FooterLink to={Paths.about}>About</FooterLink>
          <FooterLink to={Paths.buy}>Buy</FooterLink>
          <FooterLink to={Paths.play}>Play</FooterLink>
        </FooterCol>
        <FooterCol>
          <FooterOutLink href={SocialLinks.DISCORD}>Discord</FooterOutLink>
          <FooterOutLink href={SocialLinks.TELEGRAM}>Telegram</FooterOutLink>
          <FooterOutLink href={SocialLinks.TWITTER}>Twitter</FooterOutLink>
        </FooterCol>
      </FooterRow>
      <FooterRow>
        <FooterLink to={Paths.terms}>Terms of Service</FooterLink>
      </FooterRow>
    </StyledFooter>
  );
}
