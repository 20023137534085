import erc20Abi from './erc20.json';
import brawlerBuyerAbi from './brawlerBuyer.json';

export const targetChainId = 1;

export const abis = {
  erc20: erc20Abi,
  brawlerBuyer: brawlerBuyerAbi,
};

export const addresses =
  targetChainId * 1 === 1
    ? {
        game: '0x63f88A2298a5c4AEE3c216Aa6D926B184a4b2437',
        skl: '0x00c83aeCC790e8a4453e5dD3B0B4b3680501a7A7',
        brawlerBuyer: '0x2D1B617B978b140ec1662ec452Cd50f28c7104Af', // FIXME
      }
    : {
        game: '0xaFF4481D10270F50f203E0763e2597776068CBc5', // FAKE TESTNET
        skl: '0xFab46E002BbF0b4509813474841E0716E6730136', // FAKE TESTNET
        brawlerBuyer: '0x23913c0468971e84ceec48da9e95f7e46b3cf6df',
      };
