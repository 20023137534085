import './index.css';

import { DAppProvider, Mainnet, Rinkeby } from '@usedapp/core';
import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './App';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import { targetChainId } from './abis';

// if Ankr proves problematic, there are lots of node options: https://ethereumnodes.com/
const config = {
  readOnlyChainId: targetChainId,
  readOnlyUrls: {
    [Mainnet.chainId]: 'https://rpc.ankr.com/eth',
    [Rinkeby.chainId]: 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  },
};

ReactDOM.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
