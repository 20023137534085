import React from 'react';
import styled from 'styled-components/macro';
import earthCleric from 'src/images/brawlers-dark/cleric_earth.gif';
import doomWizard from 'src/images/brawlers-dark/wizard_doom.gif';
import airWarrior from 'src/images/brawlers-dark/warrior_air.gif';
import fireWitch from 'src/images/brawlers-dark/witch_fire.gif';
import doomCleric from 'src/images/brawlers-dark/cleric_doom.gif';
import fireBarbarian from 'src/images/brawlers-dark/barbarian_fire.gif';
import waterMonk from 'src/images/brawlers-dark/monk_water.gif';
import airRogue from 'src/images/brawlers-dark/rogue_air.gif';
import { H2, H3 } from './ContentSection';

const StyledTeamGrid = styled.div({
  justifyContent: 'center',
  margin: 'auto',
  display: 'grid',
  gridGap: '10px',
  gridTemplateColumns: 'repeat(auto-fill, 160px)',
});

const TeamH2 = styled(H2)({
  margin: 0,
});

const TeamH3 = styled(H3)({
  margin: 0,
});

const StyledTeamCard = styled.div({
  textAlign: 'center',
});

const StyledTeamImage = styled.img({
  width: '100%',
});

function TeamCard(image: string, name: string, title: string) {
  return (
    <StyledTeamCard key={name + title}>
      <StyledTeamImage src={image} />
      <TeamH2>{name}</TeamH2>
      <TeamH3>{title}</TeamH3>
    </StyledTeamCard>
  );
}

export function TeamGrid() {
  const team = [
    {
      image: earthCleric,
      name: 'Justin',
      title: 'CEO',
    },
    {
      image: doomWizard,
      name: 'Paul',
      title: 'CTO',
    },
    {
      image: airWarrior,
      name: 'Scott',
      title: 'Game Design',
    },
    {
      image: fireWitch,
      name: 'George',
      title: 'Game Design',
    },
    {
      image: doomCleric,
      name: 'Chris',
      title: 'Engineer',
    },
    {
      image: fireBarbarian,
      name: 'Greg',
      title: 'Engineer',
    },
    {
      image: waterMonk,
      name: 'Niall',
      title: 'Engineer',
    },
    {
      image: airRogue,
      name: 'Amanda',
      title: 'Art',
    },
  ];
  return (
    <>
      <H2>Team</H2>
      <StyledTeamGrid>{team.map((person) => TeamCard(person.image, person.name, person.title))}</StyledTeamGrid>
    </>
  );
}
