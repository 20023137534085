import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { addresses } from 'src/abis';
import { theme } from 'src/theme';
import styled from 'styled-components/macro';
import { Paths } from './paths';

const StyledRightNav = styled.div<{ open: boolean }>`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  margin: 0;
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #0d2538;
    position: fixed;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    top: 0;
    right: 0;
    font-size: 1.3em;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
  }
`;

const StyledLink = styled(Link)<{ selected: boolean }>((props) => ({
  color: theme.colors.white,
  padding: '10px 40px',
  textTransform: 'uppercase',
  fontWeight: theme.fontWeights.bold,
  textUnderlineOffset: '8px',
  textDecoration: 'none',
  ['&:hover']: {
    textDecoration: 'none',
  },
  [theme.mediaQueries.small]: {
    color: props.selected ? theme.colors.white : theme.colors.blueLight,
    padding: '18px 10px',
    marginLeft: 'unset',
    ['&:hover']: {
      textDecoration: 'underline',
    },
    textDecoration: props.selected ? 'underline' : 'none',
  },
}));

const StyledOutLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})({
  color: theme.colors.white,
  padding: '10px 40px',
  textTransform: 'uppercase',
  fontWeight: theme.fontWeights.bold,
  textUnderlineOffset: '8px',
  textDecoration: 'none',
  ['&:hover']: {
    textDecoration: 'none',
  },
  [theme.mediaQueries.small]: {
    color: theme.colors.blueLight,
    padding: '18px 10px',
    marginLeft: 'unset',
    ['&:hover']: {
      textDecoration: 'underline',
    },
  },
});

export const RightNav = (props: { open: boolean; onSelect: () => void }) => {
  const location = useLocation();
  return (
    <StyledRightNav open={props.open}>
      <StyledLink to={Paths.home} onClick={() => props.onSelect()} selected={location.pathname == Paths.home}>
        Home
      </StyledLink>
      <StyledLink to={Paths.about} onClick={() => props.onSelect()} selected={location.pathname == Paths.about}>
        About
      </StyledLink>
      <StyledLink to={Paths.buy} onClick={() => props.onSelect()} selected={location.pathname == Paths.buy}>
        Buy
      </StyledLink>
      {addresses.brawlerBuyer ? (
        <StyledOutLink href="https://play.blockbrawlers.com" onClick={() => props.onSelect()}>
          Play
        </StyledOutLink>
      ) : (
        <StyledLink to={Paths.play} onClick={() => props.onSelect()} selected={location.pathname == Paths.play}>
          Play
        </StyledLink>
      )}
    </StyledRightNav>
  );
};
