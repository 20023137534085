import React from 'react';
import { BrawlerGroup, ContentSection, KeyArt, H3, H1 } from 'src/components';
import { PageFrame } from '.';
import { JoinCommunitySection } from 'src/components/JoinCommunitySection';
import airAmazon from 'src/images/brawlers-dark/amazon_air.gif';
import doomWizard from 'src/images/brawlers-dark/wizard_doom.gif';
import fireRogue from 'src/images/brawlers-dark/rogue_fire.gif';
import earthWitch from 'src/images/brawlers-dark/witch_earth.gif';
import logo from 'src/images/bb-logo.png';
import { SectionBreak, SectionGap } from 'src/components/SectionBreak';

export function PlayPage() {
  return (
    <PageFrame>
      <SectionGap />
      <ContentSection>
        <H1>Play Block Brawlers</H1>
      </ContentSection>
      <SectionBreak />
      <BrawlerGroup imageUrls={[doomWizard, fireRogue, logo, airAmazon, earthWitch]} />
      <SectionBreak />
      <ContentSection>
        <H3>
          A play-to-earn RPG
          <br />
          powered by SKALE and GAME Credits
        </H3>
        <p>
          Test your strength against other players in the arena. Send your three Brawler team into battle! Your strategy
          choices in battle greatly influence the outcome. Win and earn fame and BRAWL tokens, and continue the battle.
          Lose, and you must return to fight another day. Win or lose, you can use the experience gained from combat to
          level up your Brawlers and improve their chances next time.
        </p>
      </ContentSection>
      <SectionBreak />
      <KeyArt />
      <SectionBreak />
      <JoinCommunitySection />
      <SectionBreak />
    </PageFrame>
  );
}
