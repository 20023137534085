import React from 'react';
import { theme } from 'src/theme';
import styled from 'styled-components/macro';

const BrawlerArtRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  overflow: 'hidden',
});

const BrawlerArt = styled.img({
  margin: '0 -20px',
  maxHeight: '120px',
  overflow: 'hidden',
});

export function BrawlerGroup(props: { imageUrls: string[] }) {
  return (
    <BrawlerArtRow>
      {props.imageUrls.map((url, index) => (
        <BrawlerArt src={url} key={`${index}-${url}`} />
      ))}
    </BrawlerArtRow>
  );
}

const SmallBrawlerArtRow = styled.div({
  position: 'absolute',
  width: '100%',
  height: '360px',
  margin: '0',
  overflow: 'hidden',
  pointerEvents: 'none',
  [theme.mediaQueries.smaller]: {
    overflow: 'unset',
    margin: '0 0 0 150px',
    maxWidth: '500px',
  },
});
const StackedBrawlerArt = styled.img<{ position: number; isRight: boolean }>((props) => ({
  display: props.isRight ? 'block' : 'none',
  position: 'absolute',
  maxHeight: '120px',
  transform: props.isRight ? 'scaleX(-1)' : '',
  left: props.isRight ? `calc(${2 - props.position} * 60px + 140px)` : '',
  right: !props.isRight ? `calc(${2 - props.position} * 60px + 140px)` : '',
  bottom: `calc(${props.position} * -50px + 120px)`,
  [theme.mediaQueries.smaller]: {
    display: 'block',
    right: props.isRight ? `calc(${2 - props.position} * -70px + 120px)` : 'unset',
    left: !props.isRight ? `calc(${2 - props.position} * -70px + 120px)` : 'unset',
  },
}));

export function SmallBrawlerGroup(props: { imageUrls: string[]; isRight: boolean }) {
  return (
    <SmallBrawlerArtRow>
      {props.imageUrls.map((url, index) => (
        <StackedBrawlerArt src={url} position={index} isRight={props.isRight} key={`${index}-${url}`} />
      ))}
    </SmallBrawlerArtRow>
  );
}
