import { Contract } from '@ethersproject/contracts';
import { addresses, abis } from '../abis';
import { CallResult } from '@usedapp/core';
import { BigNumber, ethers } from 'ethers';
import { Currency, getCSPPacks } from './cspData';

export interface UserSaleData {
  ethBalance: string;
  gameBalance: string;
  sklBalance: string;
  sales: {
    [packId: number]: {
      currencyAddress: string;
      price: string;
      available: number;
      sold: number;
      allowance: string;
      hasBought: boolean;
    };
  };
  account: string;
  isOnSale: boolean;
}

export function callList(account: string | null | undefined) {
  return [
    {
      contract: new Contract(addresses.game, abis.erc20),
      method: 'balanceOf',
      args: [account],
    },
    {
      contract: new Contract(addresses.skl, abis.erc20),
      method: 'balanceOf',
      args: [account],
    },
    /*{
      contract: new Contract(addresses.game, abis.erc20),
      method: 'allowance',
      args: [account, addresses.brawlerBuyer],
    },
    {
      contract: new Contract(addresses.skl, abis.erc20),
      method: 'allowance',
      args: [account, addresses.brawlerBuyer],
    },*/
    /*{
      contract: new Contract(addresses.brawlerBuyer, abis.brawlerBuyer),
      method: 'getPacks',
      args: [],
    },*/
    addresses.brawlerBuyer && {
      contract: new Contract(addresses.brawlerBuyer, abis.brawlerBuyer),
      method: 'getPacksForUser',
      args: [account || '0x0000000000000000000000000000000000000000'],
    },
  ];
}

export function destructureResults(
  account: string,
  ethBalance: string,
  resultArray: CallResult<Contract, string>[],
): UserSaleData {
  const formatEther = ethers.utils.formatEther;
  const userSaleData: UserSaleData = {
    account: account,
    ethBalance: ethBalance,
    gameBalance: formatEther(resultArray[0]?.value?.balance || '0'),
    sklBalance: formatEther(resultArray[1]?.value?.balance || '0'),
    // gameAllowance: formatEther(resultArray[2]?.value?.balance || '0'),
    // sklAllowance: formatEther((resultArray[3]?.value || [])[0] || '0'),
    sales: {},
    isOnSale: false,
  };
  //const saleRawData = resultArray[4]?.value || [];
  const allResults = resultArray[2]?.value;
  for (let i = 1; i < 7; i++) {
    if (addresses.brawlerBuyer) {
      userSaleData.sales[i] = {
        currencyAddress: allResults ? allResults.currency[i] : '',
        price: (+formatEther(allResults ? allResults.price[i] : '0')).toString(),
        available: allResults ? allResults.limit[i].toNumber() : 0,
        sold: allResults ? allResults.purchased[i].toNumber() : 0,
        allowance: allResults ? allResults.approvals[i].toString() : '0',
        hasBought: allResults ? (allResults.hasBought[i] as BigNumber).gt(0) : false,
      };
    } else {
      const cspPacks = getCSPPacks();
      const pack = cspPacks[i - 1];
      userSaleData.sales[i] = {
        currencyAddress:
          pack.currency === Currency.GAME ? addresses.game : pack.currency === Currency.SKALE ? addresses.skl : '',
        price: pack.price.toString(),
        available: pack.available,
        sold: 0,
        allowance: '0',
        hasBought: false,
      };
    }
  }
  userSaleData.isOnSale = allResults?.isOnSale;
  return userSaleData;
}
