import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'src/theme';
import { useState } from 'react';
import { PrimaryButton } from '.';
import { submitHubspotForm } from 'src/calls';

export const EmailSignupWrapper = styled.div`
  background: ${theme.colors.blue};

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  padding: 32px 0;
  max-width: 100%;

  ${theme.mediaQueries.small} {
    padding: 3rem 0;
  }
`;

export const EmailInputTitle = styled.h1`
  text-align: center;
  color: white;
  line-height: 39px;
`;

export const EmailInputSubTitle = styled.h5`
  line-height: 24px;
  text-align: center;
  color: white;
  margin: 16px 0;
  max-width: 480px;
  padding: 0 20px;

  ${theme.mediaQueries.small} {
    padding: 0;
  }
`;

interface EmailInputProps {
  isError?: boolean;
}

export const EmailInput = styled.input<EmailInputProps>`
  background: ${theme.colors.white};
  border: 1px solid ${(props) => (props.isError ? theme.colors.primary : theme.colors.mg)};
  box-sizing: border-box;
  font-size: 16px;
  max-width: 345px;
  width: 100%;
  padding: 8px 14px;
  color: ${(props) => props.isError && theme.colors.primary};

  &::placeholder {
    color: ${theme.colors.mg};
  }
`;

const StyledContactSection = styled.div`
  background: ${theme.colors.blueDark};
  padding: ${theme.spacing.mobileSingle};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${theme.mediaQueries.small} {
    flex-direction: row;
    align-items: unset;
  }
`;

const StayUpToDate = styled.div`
  width: 100%;
  padding: ${theme.spacing.mobileSingle};

  ${theme.mediaQueries.small} {
    width: 50%;
    max-width: 350px;
  }
`;

interface EmailSignupProps {
  isError: boolean;
}

export const EmailSignUp = styled.div<EmailSignupProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${theme.spacing.mobileSingle} ${theme.spacing.mobileSingle};

  ${theme.mediaQueries.small} {
    width: 50%;
    max-width: 350px;
    padding: ${theme.spacing.mobileSingle};
  }
`;

export const EmailHeader = styled.h1({
  color: theme.colors.white,
  textTransform: 'uppercase',
  margin: 0,
  textAlign: 'center',
  [theme.mediaQueries.small]: {
    textAlign: 'left',
  },
});
export const EmailSubHeader = styled.h4({
  color: theme.colors.white,
  textTransform: 'uppercase',
  margin: 0,
  textAlign: 'center',
  [theme.mediaQueries.small]: {
    textAlign: 'left',
  },
});
export const EmailAlert = styled.h3({
  color: theme.colors.white,
  textTransform: 'uppercase',
  margin: 0,
  textAlign: 'center',
  [theme.mediaQueries.small]: {
    textAlign: 'left',
  },
});

export function ContactSection() {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);

  async function submit(event: React.MouseEvent) {
    event.preventDefault();
    setIsError(false);
    const result = await submitHubspotForm(email);
    if (!result) {
      setIsError(true);
    } else {
      setEmail('');
      setIsSubmitted(true);
    }
  }
  return (
    <StyledContactSection>
      <StayUpToDate>
        <EmailHeader>Stay Up To Date</EmailHeader>
        <EmailSubHeader>
          Be the first to hear the
          <br />
          latest Block Brawlers news
        </EmailSubHeader>
      </StayUpToDate>

      {isSubmitted ? (
        <EmailSignUp isError={false}>
          <EmailAlert>Thanks for subscribing. You&apos;ll see your first Block Brawlers email soon!</EmailAlert>
        </EmailSignUp>
      ) : (
        <EmailSignUp isError={isError}>
          <EmailInput
            type="email"
            placeholder={'Enter Email'}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setIsError(false);
            }}
            disabled={isSubmitted}
            isError={isError}
          />
          <PrimaryButton onClick={(event) => submit(event)}>Sign Me Up!</PrimaryButton>
        </EmailSignUp>
      )}
    </StyledContactSection>
  );
}
